/** Vendor */
import { combineReducers } from "redux";

import affiliate from "./affiliate";
import article from "./article";
import assumption from "./assumption";
import auth from "./auth";
import channel from "./channel";
import contact from "./contact";
import definition from "./definition";
import device from "./device";
import file from "./file";
import flow from "./flow";
import forecast from "./forecast";
import member from "./member";
import metrics from "./metrics";
import notification from "./notification";
import preference from "./preference";
import property from "./property";
import report from "./report";
import setting from "./setting";
import socket from "./socket";
import system from "./system";
import tag from "./tag";
import testimonial from "./testimonial";
import ticket from "./ticket";
import toggle from "./toggle";
import transaction from "./transaction";
import unit from "./unit";
import video from "./video";

const root = combineReducers({
  affiliate,
  article,
  assumption,
  auth,
  channel,
  contact,
  definition,
  device,
  file,
  flow,
  forecast,
  member,
  metrics,
  notification,
  preference,
  property,
  report,
  setting,
  system,
  socket,
  tag,
  testimonial,
  ticket,
  toggle,
  transaction,
  unit,
  video,
});

export default root;
