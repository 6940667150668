//Vendor
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const details = createReducer(initialState.member.details, (builder) => {
  builder
    .addCase(createAction(types.CREATE_MEMBER_SUCCESS), (state, action) => {
      return action.member;
    })
    .addCase(createAction(types.DELETE_MEMBER_SUCCESS), () => {
      return {};
    })
    .addCase(createAction(types.GET_MEMBER_SUCCESS), (state, action) => {
      return action.member;
    })
    .addCase(createAction(types.SET_ACTIVE_MEMBER_SUCCESS), (state, action) => {
      return action.member;
    })
    .addCase(createAction(types.UPDATE_MEMBER_SUCCESS), (state, action) => {
      return { ...state, ...action.member };
    })
    .addDefaultCase((state) => state);
});

/** Generic Data for Application Drop Downs and Whatnot */
const data = createReducer(initialState.member.data, (builder) => {
  builder
    .addCase(
      createAction(types.SEARCH_MEMBER_BUSINESS_NAMES_SUCCESS),
      (state, action) => {
        return { ...state, business_names: action.results };
      }
    )
    .addCase(
      createAction(types.SEARCH_MEMBER_BUSINESS_TYPES_SUCCESS),
      (state, action) => {
        return { ...state, business_types: action.results };
      }
    )
    .addCase(
      createAction(types.SEARCH_MEMBER_CITIES_SUCCESS),
      (state, action) => {
        return { ...state, cities: action.results };
      }
    )
    .addCase(
      createAction(types.SEARCH_MEMBER_RENTAL_TYPES_SUCCESS),
      (state, action) => {
        return { ...state, rental_types: action.results };
      }
    )
    .addCase(
      createAction(types.SEARCH_MEMBER_PROPERTIES_SUCCESS),
      (state, action) => {
        return { ...state, properties: action.results };
      }
    )
    .addCase(
      createAction(types.SEARCH_MEMBER_STATES_SUCCESS),
      (state, action) => {
        return { ...state, states: action.results };
      }
    );
});

// const groupDetails = createReducer(
//   initialState.member.group.details,
//   (builder) => {
//     builder
//       .addCase(createAction(types.CREATE_GROUP_SUCCESS), (state, action) => {
//         return redux.single.addResources(state, action, "group");
//       })
//       .addCase(createAction(types.DELETE_GROUP_SUCCESS), (state, action) => {
//         return redux.single.removeMatchCase(state, action, "group");
//       })
//       .addCase(createAction(types.GET_GROUP_SUCCESS), (state, action) => {
//         return action.group || {};
//       })
//       .addCase(createAction(types.UPDATE_GROUP_SUCCESS), (state, action) => {
//         return redux.single.updateMatchCase(state, action, "group");
//       })
//       .addDefaultCase((state) => state);
//   }
// );

// const groups = createReducer(initialState.member.group.list, (builder) => {
//   builder
//     .addCase(createAction(types.CREATE_GROUP_SUCCESS), (state, action) => {
//       return redux.multi.addResources(state, action);
//     })
//     .addCase(createAction(types.DELETE_GROUP_SUCCESS), (state, action) => {
//       return redux.multi.removeMatchCase(state, action, "group");
//     })
//     .addCase(createAction(types.GET_GROUP_SUCCESS), (state, action) => {
//       return redux.multi.updateMatchCase(state, action, "group");
//     })
//     .addCase(createAction(types.SEARCH_GROUPS_SUCCESS), (state, action) => {
//       return redux.joinOrOverwrite(state, action);
//     })
//     .addCase(createAction(types.UPDATE_GROUP_SUCCESS), (state, action) => {
//       return redux.multi.updateMatchCase(state, action, "group");
//     })
//     .addDefaultCase((state) => state);
// });

const list = createReducer(initialState.member.list, (builder) => {
  builder
    .addCase(createAction(types.SEARCH_MEMBERS_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addDefaultCase((state) => state);
});

const stripe = createReducer(initialState.member.stripe, (builder) => {
  builder
    .addCase(
      createAction(types.STRIPE_SUBSCRIPTION_CANCEL),
      (state, action) => {
        return { ...state, ...action.stripe };
      }
    )
    .addCase(
      createAction(types.STRIPE_SUBSCRIPTION_CREATE),
      (state, action) => {
        return { ...state, ...action.stripe };
      }
    )
    .addCase(
      createAction(types.STRIPE_SUBSCRIPTION_DOWNGRADE),
      (state, action) => {
        return { ...state, ...action.stripe };
      }
    )
    .addCase(
      createAction(types.STRIPE_SUBSCRIPTION_REQUIRES_ACTION),
      (state, action) => {
        return { ...state, ...action.stripe };
      }
    )
    .addCase(
      createAction(types.STRIPE_SUBSCRIPTION_UPGRADE),
      (state, action) => {
        return { ...state, ...action.stripe };
      }
    )
    .addCase(
      createAction(types.STRIPE_SUBSCRIPTION_SEARCH),
      (state, action) => {
        return {
          ...state,
          subscription: action.results?.[0] || {},
        };
      }
    )
    .addCase(createAction(types.STRIPE_CARD_SEARCH), (state, action) => {
      return {
        ...state,
        cards: redux.joinOrOverwrite(state.cards, action),
      };
    })
    .addCase(createAction(types.STRIPE_INVOICE_SEARCH), (state, action) => {
      return {
        ...state,
        invoices: redux.joinOrOverwrite(state.invoices, action),
      };
    })
    .addCase(createAction(types.STRIPE_SUBSCRIPTION_ERROR), (state, action) => {
      return { ...state, error: action.stripe.error };
    })
    .addDefaultCase((state) => state);
});

// const group = combineReducers({
//   details: groupDetails,
//   list: groups,
// });

const member = combineReducers({
  data,
  details,
  // group,
  list,
  stripe,
});

export default member;
