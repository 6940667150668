/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

const details = createReducer(initialState.unit.details, (builder) => {
  builder
    .addCase(createAction(types.CREATE_PROPERTY_SUCCESS), (state, action) => {
      return action.unit || {};
    })
    .addCase(createAction(types.CREATE_UNIT_SUCCESS), (state, action) => {
      return action.unit;
    })
    .addCase(createAction(types.DELETE_UNIT_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Unit);
    })
    .addCase(createAction(types.GET_UNIT_SUCCESS), (state, action) => {
      return action.unit;
    })
    .addCase(createAction(types.SET_ACTIVE_UNIT_SUCCESS), (state, action) => {
      return action.unit;
    })
    .addCase(createAction(types.TOGGLE_FLOW_SUCCESS), (state, action) => {
      return redux.single.applyFlowControl(state, action, ResourceId.Unit);
    })
    .addCase(createAction(types.UPDATE_UNIT_SUCCESS), (state, action) => {
      return redux.single.updateMatchCase(state, action, ResourceId.Unit);
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.unit.list, (builder) => {
  builder
    .addCase(createAction(types.CREATE_PROPERTY_SUCCESS), (state) => {
      return redux.resetResources(state);
    })
    .addCase(createAction(types.CREATE_UNIT_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, ResourceId.Unit);
    })
    .addCase(createAction(types.DELETE_UNIT_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, ResourceId.Unit);
    })
    .addCase(createAction(types.SEARCH_UNITS_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.UPDATE_UNIT_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, ResourceId.Unit);
    })
    .addDefaultCase((state) => state);
});

const unit = combineReducers({ details, list });

export default unit;
