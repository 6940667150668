/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

const details = createReducer(initialState.transaction.details, (builder) => {
  builder
    .addCase(
      createAction(types.ARCHIVE_TRANSACTION_SUCCESS),
      (state, action) => {
        return redux.single.removeMatchCase(
          state,
          action,
          ResourceId.Transaction
        );
      }
    )
    .addCase(
      createAction(types.DELETE_TRANSACTION_SUCCESS),
      (state, action) => {
        return redux.single.removeMatchCase(
          state,
          action,
          ResourceId.Transaction
        );
      }
    )
    .addCase(createAction(types.GET_TRANSACTION_SUCCESS), (state, action) => {
      return action.transaction;
    })
    .addCase(createAction(types.RESET_TRANSACTIONS_SUCCESS), () => ({}))
    .addCase(createAction(types.SET_ACTIVE_PROPERTY_SUCCESS), () => ({}))
    .addCase(createAction(types.TOGGLE_FLOW_SUCCESS), (state, action) => {
      return redux.single.applyFlowControl(
        state,
        action,
        ResourceId.Transaction
      );
    })
    .addCase(
      createAction(types.UPDATE_TRANSACTION_SUCCESS),
      (state, action) => {
        return redux.single.updateMatchCase(
          state,
          action,
          ResourceId.Transaction
        );
      }
    )
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.transaction.list, (builder) => {
  builder
    .addCase(
      createAction(types.ARCHIVE_TRANSACTION_SUCCESS),
      (state, action) => {
        return redux.multi.removeMatchCase(
          state,
          action,
          ResourceId.Transaction
        );
      }
    )
    .addCase(
      createAction(types.CREATE_TRANSACTION_SUCCESS),
      (state, action) => {
        return redux.single.addResource(state, action, ResourceId.Transaction);
      }
    )
    .addCase(
      createAction(types.DELETE_TRANSACTION_SUCCESS),
      (state, action) => {
        return redux.multi.removeMatchCase(
          state,
          action,
          ResourceId.Transaction
        );
      }
    )
    .addCase(createAction(types.GET_TRANSACTION_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, ResourceId.Transaction);
    })
    .addCase(
      createAction(types.SEARCH_TRANSACTIONS_SUCCESS),
      (state, action) => {
        return redux.joinOrOverwrite(state, action);
      }
    )
    .addCase(createAction(types.RESET_TRANSACTIONS_SUCCESS), (state) => ({
      ...state,
      filtered: [],
    }))
    .addCase(
      createAction(types.SEARCH_ASSOCIATED_TRANSACTIONS_SUCCESS),
      (state, action) => {
        return redux.removeDuplicates(state, {
          ...action,
          dataSetKey: state.dataSetKey,
        });
      }
    )
    .addCase(createAction(types.SET_ACTIVE_PROPERTY_SUCCESS), () => ({
      all: [],
      filtered: [],
    }))
    .addCase(createAction(types.TOGGLE_FLOW_SUCCESS), (state, action) => {
      /**
       * @description Either reset the filtered list or reapply all transactions once flow is closed
       */
      return typeof action.is_active === "boolean" && !action.is_active
        ? { ...state, filtered: state.all }
        : action.details?.resource_id === ResourceId.Transaction
        ? { ...state, filtered: [] }
        : state;
    })
    .addCase(
      createAction(types.UPDATE_TRANSACTION_SUCCESS),
      (state, action) => {
        return redux.multi.updateMatchCase(
          state,
          action,
          ResourceId.Transaction
        );
      }
    )
    .addDefaultCase((state) => state);
});

const rate = createReducer(initialState.transaction.rate, (builder) => {
  builder
    .addCase(
      createAction(types.SEARCH_TRANSACTION_RATES_SUCCESS),
      (state, action) => redux.joinOrOverwrite(state, action)
    )
    .addDefaultCase((state) => state);
});

const transaction = combineReducers({ details, list, rate });

export default transaction;
