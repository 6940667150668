import React, { Suspense, lazy, useEffect } from "react";

/** Vendors */
import { Divider, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";

/** Redux */
import { setActivePropertyAction } from "@redux/actions/property";

/** Hooks */
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom CSS */
import "../dashboard.css";

/** Types */
import type { IRootState } from "types";

/** Custom Components */
import ContentLoading from "../../shared/loading/Content";
const Forecast = lazy(() => import("../sections/Forecast"));
const Properties = lazy(() => import("../sections/Properties"));
const Rollup = lazy(() => import("../sections/Rollup"));
const ContentView = lazy(() => import("../../shared/layout/Content"));
const HeaderInfo = lazy(() => import("../../shared/schema/Header"));

function Dashboard() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { member } = useAppSelector((state: IRootState) => ({
    member: state.member.details,
  }));

  /** Step 1. Reset the active property to empty object on render of dashboard or reidrection back to dashboard */
  useEffect(() => {
    dispatch(setActivePropertyAction({}));
  }, [location.pathname]);

  /** Step 1. Monitor changes in tour state. Ensure video variant is closed first */
  // useEffect(() => {
  //   if (tour.dashboard.run) {
  //     dispatch(toggleAction({ details: tour, resource: "tour" }));
  //   }
  // }, [tour.dashboard?.run]);

  const items = [
    {
      title: (
        <Typography.Title className="m-0" level={3}>
          <Link to="/dashboard">Welcome {member?.given_name}</Link>
        </Typography.Title>
      ),
    },
  ];

  return (
    <Suspense fallback={<ContentLoading />}>
      <HeaderInfo details={{}} page="dashboard" />
      <ContentView items={items}>
        <Rollup key="dashboard-widget-1" />
        <Divider />
        <Forecast key="dashboard-widget-2" />
        <Divider />
        <Properties key="dashboard-widget-3" />
      </ContentView>
      <div />
      <div />
    </Suspense>
  );
}

Dashboard.displayName = "Dashboard";

export default Dashboard;
