/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const toggler = redux.single.toggleState;

const toggle = createReducer(initialState.toggle, (builder) => {
  builder
    .addCase(createAction(types.TOGGLE_AFFILIATE_MODAL), (state, action) => {
      return toggler(state, action, "affiliateModal");
    })
    .addCase(createAction(types.TOGGLE_ARTICLE_MODAL), (state, action) => {
      return toggler(state, action, "articleModal");
    })
    .addCase(createAction(types.TOGGLE_ASSUMPTIONS_MODAL), (state, action) => {
      return toggler(state, action, "assumptionsModal");
    })
    .addCase(createAction(types.TOGGLE_CHAT_SIDEBAR), (state, action) => {
      return toggler(state, action, "chatSidebar");
    })
    .addCase(createAction(types.TOGGLE_CONTACT_MODAL), (state, action) => {
      return toggler(state, action, "contactModal");
    })
    .addCase(createAction(types.TOGGLE_CONTACTS_LIST), (state, action) => {
      return toggler(state, action, "contactsList");
    })
    .addCase(createAction(types.TOGGLE_DEFINITION_DRAWER), (state, action) => {
      return toggler(state, action, "definitionDrawer");
    })
    .addCase(createAction(types.TOGGLE_FILE_MODAL), (state, action) => {
      return toggler(state, action, "fileModal");
    })
    .addCase(createAction(types.TOGGLE_LEFT_SIDEBAR), (state, action) => {
      return toggler(state, action, "leftSidebar");
    })
    .addCase(
      createAction(types.TOGGLE_NOTIFICATIONS_MODAL),
      (state, action) => {
        return toggler(state, action, "notificationModal");
      }
    )
    .addCase(createAction(types.TOGGLE_PREFERENCE_MODAL), (state, action) => {
      return toggler(state, action, "preferenceModal");
    })
    .addCase(createAction(types.TOGGLE_PROPERTY_MODAL), (state, action) => {
      return toggler(state, action, "propertyModal");
    })
    .addCase(createAction(types.TOGGLE_RIGHT_SIDEBAR), (state, action) => {
      return toggler(state, action, "rightSidebar");
    })
    .addCase(
      createAction(types.TOGGLE_SUBSCRIPTION_SIDEBAR),
      (state, action) => {
        return toggler(state, action, "subscriptionSidebar");
      }
    )
    .addCase(createAction(types.TOGGLE_SYSTEM_MODAL), (state, action) => {
      return toggler(state, action, "systemModal");
    })
    .addCase(
      createAction(types.TOGGLE_TAX_DISCLAIMER_MODAL),
      (state, action) => {
        return toggler(state, action, "taxDisclaimerModal");
      }
    )
    .addCase(createAction(types.TOGGLE_TESTIMONIAL_MODAL), (state, action) => {
      return toggler(state, action, "testimonialModal");
    })
    .addCase(createAction(types.TOGGLE_TRANSACTION_MODAL), (state, action) => {
      return toggler(state, action, "transactionModal");
    })
    .addCase(
      createAction(types.TOGGLE_TRANSACTION_COLUMNS_MODAL),
      (state, action) => {
        return toggler(state, action, "transactionColumnModal");
      }
    )
    .addCase(
      createAction(types.TOGGLE_TRANSACTIONS_EXPORT_MODAL),
      (state, action) => {
        return toggler(state, action, "transactionExportModal");
      }
    )
    .addCase(createAction(types.TOGGLE_TICKET_MODAL), (state, action) => {
      return toggler(state, action, "ticketModal");
    })
    .addCase(createAction(types.TOGGLE_TUTORIAL_MODAL), (state, action) => {
      return toggler(state, action, "tutorialModal");
    })
    .addCase(createAction(types.TOGGLE_TOUR_SUCCESS), (state, action) => {
      return toggler(state, action, "tour");
    })
    .addDefaultCase((state) => state);
});

export default toggle;
