/** Enums */
import { ResourceId, SystemName } from "../../types";

/** Types */
import type {
  IAffiliate,
  IArticle,
  IAssumption,
  IChannel,
  IContact,
  IDefinition,
  IDevice,
  IFile,
  IForecast,
  IMember,
  IMemberData,
  IMetricsPortfolio,
  IMetricsTemplate,
  INotification,
  INotificationTemplate,
  INumberObject,
  IPreference,
  IProperty,
  IReport,
  ISearchArticle,
  ISearchContact,
  ISearchDefinition,
  ISearchProperty,
  ISearchSetting,
  ISearchTransactionRatesResponse,
  ISearchTransactions,
  ISearchUnit,
  ISetting,
  IStandardList,
  IStripe,
  IStripeSubscription,
  ISystem,
  ITag,
  ITestimonial,
  ITicket,
  ITicketNote,
  ITransaction,
  IUnit,
  IVideo,
} from "../../types";

export type IInitialState = {
  affiliate: {
    details: IAffiliate;
    list: IStandardList<IAffiliate>;
  };
  assumption: {
    details: IAssumption;
    list: IStandardList<IAssumption>;
  };
  auth: {
    is_admin: boolean;
    is_intro_complete: boolean;
    is_returning: boolean;
  };
  article: {
    details: IArticle;
    featured: IArticle;
    list: IStandardList<IArticle>;
    search: ISearchArticle;
  };
  channel: {
    details: IChannel;
    list: IStandardList<IChannel>;
  };
  contact: {
    details: IContact;
    list: IStandardList<IContact>;
    search: ISearchContact;
  };
  definition: {
    details: IDefinition;
    list: IStandardList<IDefinition>;
    search: ISearchDefinition;
  };
  device: IDevice;
  file: {
    dropped: IStandardList<IFile>;
    list: IStandardList<IFile>;
  };
  flow: {
    is_active: boolean;
    /** Any Object */
    details: any;
  };
  forecast: {
    portfolio: IForecast;
    property: IForecast;
    unit: IForecast;
  };
  member: {
    data: IMemberData;
    details: IMember;
    list: IStandardList<IMember>;
    stripe: IStripe;
  };
  metrics: {
    portfolio: IMetricsPortfolio;
    property: IMetricsPortfolio;
    properties: IStandardList<IMetricsPortfolio>;
    template: {
      details: IMetricsTemplate;
      list: IStandardList<IMetricsTemplate>;
    };
    unit: IMetricsPortfolio;
    units: IStandardList<IMetricsPortfolio>;
  };
  notification: {
    details: INotification;
    list: IStandardList<INotification>;
    template: {
      details: INotificationTemplate;
      list: IStandardList<INotificationTemplate>;
    };
  };
  preference: {
    details: IPreference;
    list: IStandardList<IPreference>;
  };
  property: {
    details: IProperty;
    list: IStandardList<IProperty>;
    mortgage: INumberObject[];
    search: ISearchProperty;
  };
  report: {
    details: IReport;
    list: IStandardList<IReport>;
  };
  allReport: {
    list: IStandardList<IReport>;
  };
  setting: {
    details: ISetting;
    list: IStandardList<ISetting>;
    search: ISearchSetting;
  };
  socket: { status: "connected" | "disconnected" | "refresh" | string };
  system: {
    industry: {
      average: {
        lifespan?: number;
        replacement_cost?: number;
      };
      cities: string[];
      data: Array<{
        l: number;
        r: number;
      }>;
      name: SystemName;
    };
    details: ISystem;
    list: IStandardList<ISystem>;
  };
  tag: {
    details: ITag;
    list: IStandardList<ITag>;
  };
  testimonial: {
    details: ITestimonial;
    list: IStandardList<ITestimonial>;
  };
  toggle: {
    [key: string]: boolean;
  };
  transaction: {
    details: ITransaction;
    list: IStandardList<ITransaction>;
    rate: IStandardList<ISearchTransactionRatesResponse>;
    search: ISearchTransactions;
  };
  ticket: {
    details: ITicket;
    list: IStandardList<ITicket>;
    note: ITicketNote;
    notes: IStandardList<ITicketNote>;
  };
  unit: {
    details: IUnit;
    list: IStandardList<IUnit>;
    search: ISearchUnit;
  };
  video: {
    category: {
      details: IVideo["category"];
      list: IVideo["category"][];
    };
    details: IVideo;
    list: IStandardList<IVideo>;
  };
};

const defaultForecast: IForecast = {
  annual: {
    cashflow: 0,
    cashflow_estimated: 0,
    maintenance: 0,
    maintenance_estimated: 0,
    mortgage: 0,
    mortgage_estimated: 0,
    rent: 0,
    rent_estimated: 0,
    rent_with_vacancy_estimated: 0,
    vacancy: 0,
    vacancy_estimated: 0,
  },
  data: {
    delta: {},
    expected: {},
    forecast: {},
    real: {},
  },
  month_over_month: {
    cashflow: 0,
    cashflow_estimated: 0,
    maintenance: 0,
    maintenance_estimated: 0,
    mortgage: 0,
    mortgage_estimated: 0,
    rent: 0,
    rent_estimated: 0,
    rent_with_vacancy_estimated: 0,
    vacancy: 0,
    vacancy_estimated: 0,
  },
  total: {
    cashflow: 0,
    cashflow_estimated: 0,
    maintenance: 0,
    maintenance_estimated: 0,
    mortgage: 0,
    mortgage_estimated: 0,
    rent: 0,
    rent_estimated: 0,
    rent_with_vacancy_estimated: 0,
    vacancy: 0,
    vacancy_estimated: 0,
  },
};

const defaultMetrics: IMetricsPortfolio = {
  annual: {
    cashflow: 0,
    cashflow_percent: 0,
    equity: 0,
    equity_percent: 0,
    maintenance: 0,
    maintenance_estimated: 0,
    mortgage: 0,
    mortagage_estimated: 0,
    rent: 0,
    rent_estimated: 0,
    roi_percent: 0,
    roi_percent_estimated: 0,
    vacancy_percent: 0,
  },
  month_over_month: {
    cashflow: 0,
    cashflow_estimated: 0,
    equity: 0,
    equity_percent: 0,
    maintenance: 0,
    maintenance_estimated: 0,
    mortgage: 0,
    mortgage_estimated: 0,
    property_management_fee: 0,
    rent: 0,
    roi_percent: 0,
    roi_percent_estimated: 0,
    vacancy_percent: 0,
  },
  property: "",
  total: {
    appraisal: 0,
    appraisal_percent: 0,
    cap_rate: 0,
    cashflow: 0,
    cashflow_estimated: 0,
    equity: 0,
    equity_percent: 0,
    land_value: 0,
    legal_professional_fee: 0,
    loan_average_rate: 0,
    loan_to_value: 0,
    maintenance: 0,
    maintenance_estimated: 0,
    net_operating_income: 0,
    price_per_square_foot: 0,
    property_tax: 0,
    rent: 0,
    rent_estimated: 0,
    roi_percent: 0,
    roi_percent_estimated: 0,
    square_feet: 0,
    tax_assessment: 0,
    units: 0,
    vacancy_percent: 0,
    vacancy_percent_estimated: 0,
  },
  unit: "",
};

const standardList = {
  all: [],
  dataSetKey: "",
  dataSetSize: 0,
  filtered: [],
  loading: false
};

const initialState: IInitialState = {
  affiliate: {
    details: {} as IAffiliate,
    list: standardList,
  },
  article: {
    details: {} as IArticle,
    featured: {} as IArticle,
    list: standardList,
    search: {} as ISearchArticle,
  },
  assumption: {
    details: {} as IAssumption,
    list: standardList,
  },
  auth: {
    is_admin: false,
    is_intro_complete: false,
    is_returning: false,
  },
  channel: {
    details: {} as IChannel,
    list: standardList,
  },
  contact: {
    details: {} as IContact,
    list: standardList,
    search: {} as ISearchContact,
  },
  definition: {
    details: {} as IDefinition,
    list: standardList,
    search: {} as ISearchDefinition,
  },
  device: {
    loading: true,
    mobile: window.innerWidth < 769 ? true : false,
    tour: {
      dashboard: {
        completed: 0,
        run: true,
      },
      profile: {
        completed: 0,
        run: true,
      },
      property: {
        completed: 0,
        run: true,
      },
      video: {
        completed: 0,
        run: true,
      },
    },
    width: window.innerWidth,
  },
  file: {
    dropped: standardList,
    list: standardList,
  },
  flow: {
    details: { resource_id: ResourceId.Property } as any,
    is_active: false,
  },
  forecast: {
    portfolio: defaultForecast,
    property: defaultForecast,
    unit: defaultForecast,
  },
  member: {
    data: {
      business_names: [],
      business_types: [],
      cities: [],
      properties: [],
      rental_types: [],
      states: [],
    },
    details: {} as IMember,
    list: standardList,
    stripe: {
      cards: standardList,
      error: null,
      invoices: standardList,
      subscription: {} as IStripeSubscription,
      subscriptions: standardList,
    },
  },
  metrics: {
    portfolio: defaultMetrics,
    properties: standardList,
    property: defaultMetrics,
    template: {
      details: {} as IMetricsTemplate,
      list: standardList,
    },
    unit: defaultMetrics,
    units: standardList,
  },
  notification: {
    details: {} as INotification,
    list: standardList,
    template: {
      details: {} as INotificationTemplate,
      list: standardList,
    },
  },
  preference: {
    details: {} as IPreference,
    list: standardList,
  },
  property: {
    details: {} as IProperty,
    list: standardList,
    mortgage: [],
    search: {} as ISearchProperty,
  },
  report: {
    details: {} as IReport,
    list: standardList,
  },
  setting: {
    details: {
      tax_first_year: new Date().getFullYear() - 1,
      tax_last_year: new Date().getFullYear(),
    } as ISetting,
    list: standardList,
    search: {} as ISearchSetting,
  },
  socket: { status: "disconnected" },
  system: {
    details: {} as ISystem,
    industry: {
      average: { lifespan: 0, replacement_cost: 0 },
      cities: [],
      data: [],
      name: SystemName.Roof,
    },
    list: standardList,
  },
  tag: {
    details: {} as ITag,
    list: standardList,
  },
  testimonial: {
    details: {} as ITestimonial,
    list: standardList,
  },
  ticket: {
    details: {} as ITicket,
    list: standardList,
    note: {} as ITicketNote,
    notes: standardList,
  },
  toggle: {
    affiliateModal: false,
    assumptionsModal: false,
    chatSidebar: false,
    contactModal: false,
    contactList: false,
    fileModal: false,
    leftSidebar: false,
    notificationModal: false,
    preferenceModal: false,
    propertyModal: false,
    subscriptionSidebar: false,
    systemModal: false,
    taxDisclaimerModal: false,
    testimonialModal: false,
    transactionColumnModal: false,
    transactionModal: false,
    tutorialModal: false,
    tour: false,
  },
  transaction: {
    details: {} as ITransaction,
    list: standardList,
    rate: standardList,
    search: {} as ISearchTransactions,
  },
  unit: {
    details: {} as IUnit,
    list: standardList,
    search: {} as ISearchUnit,
  },
  video: {
    category: {
      details: {} as IVideo["category"],
      list: [],
    },
    details: {} as IVideo,
    list: standardList,
  },
};

export default initialState;
