/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

const details = createReducer(initialState.channel.details, (builder) => {
  builder
    .addCase(createAction(types.DELETE_CHANNEL_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Channel);
    })
    .addCase(createAction(types.CREATE_CHANNEL_SUCCESS), (state, action) => {
      return action.channel || {};
    })
    .addCase(createAction(types.GET_CHANNEL_SUCCESS), (state, action) => {
      return action.channel || {};
    })
    .addCase(
      createAction(types.SET_ACTIVE_CHANNEL_SUCCESS),
      (state, action) => {
        return action.channel || {};
      }
    )
    .addCase(createAction(types.TOGGLE_CHAT_SIDEBAR), (state, action) => {
      return action.details || {};
    })
    .addCase(createAction(types.UPDATE_CHANNEL_SUCCESS), (state, action) => {
      return redux.single.updateMatchCase(state, action, ResourceId.Channel);
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.channel.list, (builder) => {
  builder
    .addCase(createAction(types.DELETE_CHANNEL_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Channel);
    })
    .addCase(createAction(types.CREATE_CHANNEL_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, ResourceId.Channel);
    })
    .addCase(createAction(types.SEARCH_CHANNELS_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.UPDATE_CHANNEL_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, ResourceId.Channel);
    })
    .addDefaultCase((state) => state);
});

const channel = combineReducers({ details, list });

export default channel;
