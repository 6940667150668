/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

const socket = createReducer(initialState.socket, (builder) => {
  builder
    .addCase(createAction(types.SOCKET_CONNECTED), () => {
      return { status: "connected" };
    })
    .addCase(createAction(types.SOCKET_DISCONNECTED), () => {
      return { status: "disconnected" };
    })
    .addCase(createAction(types.SOCKET_REFRESH), () => {
      return { status: "refresh" };
    })
    .addDefaultCase((state) => state);
});

export default socket;
