import React from "react";

/** Vendors */
import { Col, Flex, Row, Skeleton, Typography } from "antd";

/** Custom Components */
import ContentView from "../layout/Content";
import Section from "../card/Section";

function ContentLoading() {
  const CardSkeleton = (props = {}) => (
    <Skeleton.Input active block size="large" {...props} />
  );

  return (
    <React.Fragment>
      <Flex justify="center" vertical>
        <Row align="middle" className="pt-4 pb-4" gutter={[24, 24]}>
          <Col
            className="flex flex-row flex-between"
            lg={16}
            md={14}
            sm={12}
            xs={24}
          >
            <Typography.Title className="m-0" level={3}>
              <Skeleton.Input style={{ background: "rgba(0, 0, 0, 0.5)" }} />
            </Typography.Title>
          </Col>
        </Row>
      </Flex>
      <ContentView style={{ height: "100vh" }}>
        <Section extra={<Skeleton.Input />} title={<Skeleton.Input />}>
          <Row className="w-100" gutter={[36, 36]}>
            {Array(8)
              .fill(0)
              .map((_, i) => (
                <Col lg={6} md={8} sm={12} xs={24} key={`skeleton-${i}`}>
                  <CardSkeleton style={{ height: 100 }} />
                </Col>
              ))}
          </Row>
        </Section>
        <Section
          actions={{}}
          extra={<Skeleton.Input />}
          title={<Skeleton.Input />}
        >
          <Row className="w-100" gutter={[36, 36]}>
            {Array(8)
              .fill(0)
              .map((_, i) => (
                <Col lg={6} md={8} sm={12} xs={24} key={`skeleton-${i}`}>
                  <CardSkeleton style={{ height: 100 }} />
                </Col>
              ))}
          </Row>
        </Section>
      </ContentView>
    </React.Fragment>
  );
}

export default ContentLoading;
