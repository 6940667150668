import React, { useContext, useState } from "react";

/** Vendor */
import { NavLink } from "react-router-dom";
import { TabBar } from "antd-mobile";
import { Layout, Menu, Skeleton, Tag, Typography } from "antd";

/** Custom Hooks */
import { useAppSelector } from "@hooks/useRedux";
import useDevice from "@hooks/useDevice";

/** Auth Context */
import { AuthContext } from "../../auth/shared/Context";

/** Custom Methods */
import { formatName } from "@dist/js/support";

/** Custom Components */
import Avatar from "@components/shared/member/Avatar";

/** Custom CSS */
import "../sidebar/sidebar.css";

export default function SidebarLoading() {
  const [collapsed, setCollapsed] = useState<boolean>(window.innerWidth > 768);
  const device = useDevice();
  const member = useAppSelector((state) => state.member.details);
  const subscription = useContext(AuthContext);

  const avatarStyle = {
    background: "rgba(0, 0, 0, 0.5)",
    height: 40,
    width: 40,
  };

  /** Determine Subscription Text to Display in Sidebar */
  let subscription_label = "ROOKIE";
  if (subscription.stripe_subscription_level === 0) {
    subscription_label = "GUEST";
  } else if (subscription.stripe_subscription_level === 1) {
    subscription_label = "ROOKIE";
  } else if (subscription.stripe_subscription_level === 2) {
    subscription_label = "PRO";
  } else if (subscription.stripe_subscription_level === 3) {
    subscription_label = "ALL STAR";
  } else if (subscription.stripe_subscription_level === 4) {
    subscription_label = "ALL STAR";
  }

  const items = [
    {
      icon: (
        <div className="text-center">
          <Skeleton.Avatar style={avatarStyle} />
        </div>
      ),
      key: "sider-loading-menu-option-1",
      label: <NavLink to="/dashboard">Dashboard</NavLink>,
    },
    {
      icon: (
        <div className="text-center">
          <Skeleton.Avatar style={avatarStyle} />
        </div>
      ),
      key: "sider-loading-menu-option-2",
      label: <NavLink to="/contacts">Contacts</NavLink>,
    },
    {
      icon: (
        <div className="text-center">
          <Skeleton.Avatar style={avatarStyle} />
        </div>
      ),
      key: "sider-loading-menu-option-3",
      label: <NavLink to="/capex">CapEx Items</NavLink>,
    },
    {
      icon: (
        <div className="text-center">
          <Skeleton.Avatar style={avatarStyle} />
        </div>
      ),
      key: "sider-loading-menu-option-4",
      label: <NavLink to="/documents">Documents Hub</NavLink>,
    },
    {
      icon: (
        <div className="text-center">
          <Skeleton.Avatar style={avatarStyle} />
        </div>
      ),
      key: "sider-loading-menu-option-5",
      label: <NavLink to="/reports">Reports</NavLink>,
    },
    {
      icon: (
        <div className="text-center">
          <Skeleton.Avatar style={avatarStyle} />
        </div>
      ),
      key: "sider-loading-menu-option-6",
      label: <NavLink to="/education">Education</NavLink>,
    },
  ];

  /** Add Definition Manager If Admin */
  if (subscription?.admin) {
    items.push({
      icon: (
        <div className="text-center">
          <Skeleton.Avatar style={avatarStyle} />
        </div>
      ),
      key: "sider-loading-menu-option-7",
      label: <NavLink to="/platform">Platform</NavLink>,
    });
  }

  items.push({
    icon: (
      <div style={{ height: 50, width: 50 }}>
        <Avatar enableTooltip={false} members={[member]} />
      </div>
    ),
    key: "sider-loading-menu-option-8",
    label: (
      <NavLink to="/profile/details">
        <div className="user info">
          <div>{formatName(member)}</div>
          {!device.isMobile && <div>{member?.email}</div>}
        </div>
      </NavLink>
    ),
  });

  items.push({
    icon: null,
    key: "sider-loading-menu-option-9",
    label: <Tag>{subscription_label}</Tag>,
  });

  const mobileIgnore = [
    "sider-loading-menu-option-0",
    "sider-loading-menu-option-9",
  ];

  return device.isMobile ? (
    <TabBar>
      {items
        .filter((item) => !mobileIgnore.includes(item.key))
        .map((item) => (
          <TabBar.Item icon={item.icon} key={item.key} title={item.label} />
        ))}
    </TabBar>
  ) : (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light"
      width={300}
    >
      <div className="logo">
        <Skeleton.Avatar
          style={{
            background: "rgba(0, 0, 0, 0.5)",
            height: 40,
            marginTop: -8,
            marginLeft: 8,
            width: 40,
          }}
        />
        {collapsed ? null : (
          <Typography.Title className="text-white" level={4}>
            TRACTIC
          </Typography.Title>
        )}
      </div>
      <Menu
        className={
          subscription?.admin
            ? "admin flex-1 loading"
            : "not-admin flex-1 loading"
        }
        items={items}
        mode="inline"
        theme="light"
      />
    </Layout.Sider>
  );
}
