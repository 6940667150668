/** Vendors */
import dayjs from "dayjs";

/** Enums */
import {
  Auth0Role,
  EntityType,
  FileTag,
  RentalType,
  ResourceId,
  SystemName,
  TestimonialCategory,
  TransactionType,
} from "types";

/** Types */
import { IMember, IStringObject, ITransaction, IUnit } from "types";

interface ISortLists {
  baseline: string[];
  data: Array<IStringObject & { label: string; value: string }>;
}

const _dateFields = [
  "created",
  "date_purchased",
  "installed",
  "insurance_end",
  "insurance_start",
  "lease_end",
  "lease_start",
  "timestamp",
  "updated",
];

export const auth0Roles: IStringObject[] = [
  { label: "Contact", value: Auth0Role.Contact },
  { label: "Assistant", value: Auth0Role.Assistant },
  { label: "Investor", value: Auth0Role.Investor },
  { label: "Investment Bank", value: Auth0Role.InvestmentBank },
  { label: "Tax Professional", value: Auth0Role.Tax },
];

export const capexEnumToLabel: IStringObject = {
  appliances: "Appliances",
  fridge: "Fridge",
  hvac: "HVAC",
  roof: "Roof",
  solar: "Solar",
  stove: "Stove",
  washer: "Washing Machine",
  water_heater: "Water Heater",
  windows: "Windows",
};

export const entityTypes = [
  { label: "Business Trust", value: EntityType.Trust },
  { label: "Corporation (C or S Corp)", value: EntityType.Corporation },
  { label: "Cooperative (Co-op)", value: EntityType.COOP },
  { label: "General Partnership (GP)", value: EntityType.GeneralPartnership },
  { label: "Joint Venture", value: EntityType.JoinVenture },
  { label: "Limited Liability Company (LLC)", value: EntityType.LLC },
  {
    label: "Limited Liability Partnership (LLP)",
    value: EntityType.LLP,
  },
  { label: "Limited Partnership (LP)", value: EntityType.LP },
  { label: "Real Estate Investment Trust (REIT)", value: EntityType.REIT },
  { label: "Sole Proprietorship", value: EntityType.SoleProprietor },
];

export const propertyTypes: IStringObject[] = [
  { label: "SFH", value: "SFH" },
  { label: "Multifamily", value: "MultiFamily" },
  { label: "Condo", value: "Condo" },
  { label: "Town Home", value: "TownHome" },
  { label: "BRRR", value: "BRRR" },
  { label: "Other", value: "Other" },
];

export const relationshipTypes: IStringObject[] = [
  { label: "All", value: "all" },
  { label: "Closing Agent", value: "closing" },
  { label: "Director of Operations", value: "operations_director" },
  { label: "Financing", value: "financing" },
  { label: "Founder", value: "founder" },
  { label: "Leasing Manager", value: "leasing_manager" },
  { label: "Loan Originator", value: "loan_originator" },
  { label: "Operations Manager", value: "operations_manager" },
  { label: "Owner", value: "owner" },
  { label: "Property Management", value: "property_management" },
  { label: "President", value: "president" },
  { label: "Property Mgmt Lead", value: "property_management_lead" },
  { label: "Seller", value: "seller" },
  { label: "Senior Loan Originator", value: "senior_loan_originator" },
  { label: "Title Agent", value: "title" },
];

export const rentalTypes = [
  { label: "Long Term Rental", value: RentalType.LongTermRentals },
  { label: "Short Term Rental", value: RentalType.ShortTermRentals },
  { label: "Syndication", value: RentalType.Syndication },
  { label: "Other", value: RentalType.Other },
];

export const systemTypes = [
  { label: "Appliances", value: SystemName.Appliances },
  { label: "Fridge", value: SystemName.Fridge },
  { label: "HVAC", value: SystemName.HVAC },
  { label: "Roof", value: SystemName.Roof },
  { label: "Solar", value: SystemName.Solar },
  { label: "Stove", value: SystemName.Stove },
  { label: "Washing Machine", value: SystemName.WashingMachine },
  { label: "Water Heater", value: SystemName.WaterHeater },
  { label: "Windows", value: SystemName.Windows },
];

//Overview Display Popover Related Select Values
export const transactionTypes = [
  {
    column_enabled: true,
    extra: "The rent collected from your tenants",
    group: "defaults",
    group_label: "Standard Calculations",
    is_loss: false,
    label: "Rent",
    order: 0,
    value: "rent",
  },
  {
    column_enabled: true,
    extra:
      "This is the amount that the property management company would charge you each month for their services.",
    group: "defaults",
    group_label: "Standard Calculations",
    is_loss: true,
    label: "Property Management Fee",
    order: 1,
    value: "property_management_fee",
  },
  {
    column_enabled: true,
    definition: "maintenance_repair",
    extra:
      "The sum of maintenance and repair activities.",
    group: "defaults",
    group_label: "Standard Calculations",
    is_loss: true,
    label: "Maintenance and Repairs",
    order: 2,
    value: "maintenance_repair",
  },
  {
    column_enabled: true,
    extra:
      "The loan amount you are using to finance the purchase of this property",
    group: "defaults",
    group_label: "Standard Calculations",
    is_loss: true,
    label: "Mortgage",
    order: 3,
    value: "mortgage",
  },
  {
    column_enabled: true,
    extra:
      "The total amount of money being transferred into and out of a business, especially as affecting liquidity",
    group: "defaults",
    group_label: "Standard Calculations",
    is_loss: false,
    label: "Cash Flow",
    order: 4,
    value: "cashflow",
  },
  {
    column_enabled: false,
    extra:
      "Track expenses related to promoting vacant units, including online listings, print ads, or signage.",
    group: "advertising",
    group_label: "Advertising and Marketing",
    is_loss: true,
    label: "Advertising and Marketing",
    order: 5,
    value: "advertising",
  },
  {
    column_enabled: false,
    extra:
      "Record expenses for major property upgrades or renovations that add value to the property.",
    group: "capital_improvement",
    group_label: "Capital Improvement",
    is_loss: true,
    label: "Capital Improvement",
    order: 6,
    value: "capital_improvement",
  },
  {
    column_enabled: false,
    definition: "maintenance",
    extra:
      "Systematic process of preserving and enhancing the condition of a property, whether residential or commercial, through regular inspections, repairs, and upkeep to ensure its longevity, functionality, and attractiveness.",
    group: "maintenance",
    group_label: "Maintenance and Repairs",
    is_loss: true,
    label: "Maintenance",
    order: 7,
    value: "maintenance",
  },
  {
    column_enabled: false,
    definition: "repair",
    extra:
      "Necessary actions taken to fix, restore, or replace damaged, broken, or worn-out components of a property, ensuring its structural integrity, functionality, and overall appearance are maintained or improved.",
    group: "maintenance",
    group_label: "Maintenance and Repairs",
    is_loss: true,
    label: "Repair",
    order: 8,
    value: "repair",
  },
  {
    column_enabled: false,
    extra:
      "Account for commissions paid to leasing agents or brokers for securing new tenants.",
    group: "commission",
    group_label: "Leasing Commissions",
    is_loss: false,
    label: "Leasing Commission",
    order: 9,
    value: "commission",
  },
  {
    column_enabled: false,
    extra:
      "Track monthly insurance premium payments for property, liability, and other insurance types.",
    group: "insurance",
    group_label: "Insurance Premiums",
    is_loss: true,
    label: "Insurance Premiums",
    order: 10,
    value: "insurance",
  },
  {
    column_enabled: false,
    extra:
      "Protection from the shaking and cracking that can destroy buildings and personal possessions. Earthquake damage is usually not covered by standard homeowners insurance policies",
    group: "insurance",
    group_label: "Insurance Premiums",
    is_loss: true,
    label: "Earthquake Insurance",
    order: 11,
    value: "earthquake_insurance",
  },
  {
    column_enabled: false,
    extra:
      "Protection that covers dwellings for losses caused by flooding from heavy or prolonged rain, melting snow, a coastal storm surge, blocked storm drainage systems or levee dam failure",
    group: "insurance",
    group_label: "Insurance Premiums",
    is_loss: true,
    label: "Flood Insurance",
    order: 12,
    value: "flood_insurance",
  },
  {
    column_enabled: false,
    extra:
      "Protection for a homeowner and/or business owner against physical damages caused by unexpected and sudden events such as fires, severe storms, and other events",
    group: "insurance",
    group_label: "Insurance Premiums",
    is_loss: true,
    label: "Hazard Insurance",
    order: 13,
    value: "hazard_insurance",
  },
  {
    column_enabled: false,
    extra:
      "Protection for loss or damage caused by windstorm during a hurricane",
    group: "insurance",
    group_label: "Insurance Premiums",
    is_loss: true,
    label: "Hurricane Insurance",
    order: 14,
    value: "hurricane_insurance",
  },
  {
    column_enabled: false,
    extra:
      "Protects you legally and financially from damages or injuries related to a rental property you own",
    group: "insurance",
    group_label: "Insurance Premiums",
    is_loss: true,
    label: "Landlord Insurance",
    order: 15,
    value: "landlord_insurance",
  },
  {
    column_enabled: false,
    extra:
      "The principal portion of your mortgage that has been paid back. Requires 'Advanced Mortgage' inputs.",
    group: "mortgage",
    group_label: "Mortgage",
    is_loss: true,
    label: "Principal",
    order: 16,
    value: "principal",
  },
  {
    column_enabled: false,
    extra: "Repayment of your financed loan for the property",
    group: "mortgage",
    group_label: "Mortgage",
    is_loss: true,
    label: "Loan Interest",
    order: 17,
    value: "loan_interest",
  },
  {
    column_enabled: false,
    extra:
      "Allocate and record monthly property tax payments, or escrow amounts if applicable.",
    group: "mortgage",
    group_label: "Mortgage",
    is_loss: true,
    label: "Property Tax",
    order: 18,
    value: "property_tax",
  },
  {
    column_enabled: false,
    extra: "Repayment of your financed loan for the property",
    group: "mortgage",
    group_label: "Mortgage",
    is_loss: true,
    label: "Heloc Payment",
    order: 19,
    value: "heloc",
  },
  {
    column_enabled: false,
    extra:
      "Refunds for purchases or other transactions for your rental property",
    group: "income",
    group_label: "Income",
    is_loss: false,
    label: "Refund",
    order: 20,
    value: "refund",
  },
  {
    column_enabled: false,
    extra: "Income earned during the operation of your rental property",
    group: "income",
    group_label: "Income",
    is_loss: false,
    label: "Royalties Received",
    order: 21,
    value: "royalties",
  },
  {
    column_enabled: false,
    extra: "The sale of an asset or property",
    group: "income",
    group_label: "Income",
    is_loss: false,
    label: "Sale",
    order: 22,
    value: "sale",
  },
  {
    column_enabled: false,
    extra:
      "Security Deposit obtained from your tenants. Can be either positive or negative.",
    group: "income",
    group_label: "Income",
    is_loss: false,
    label: "Security Deposit",
    order: 23,
    value: "security_deposit",
  },
  {
    column_enabled: false,
    extra:
      "Record expenses for legal and professional services, such as attorney fees, accounting services, or consultant fees.",
    group: "legal_professional_fee",
    group_label: "Legal & Professional Fees",
    is_loss: true,
    label: "Legal & Professional Fee",
    order: 24,
    value: "legal_professional_fee",
  },
  {
    column_enabled: false,
    extra:
      "Track any office-related expenses, including supplies, software subscriptions, and postage.",
    group: "office_supplies",
    group_label: "Office Supplies & Expenses",
    is_loss: true,
    label: "Office Supplies",
    order: 25,
    value: "office_supplies",
  },

  {
    column_enabled: false,
    extra:
      "Allocate and record monthly depreciation expenses for tax purposes.",
    group: "depreciation",
    group_label: "Depreciation",
    is_loss: true,
    label: "Depreciation",
    order: 26,
    value: "depreciation",
  },
  {
    column_enabled: false,
    extra:
      "Telephone fees incurred during the operation of your rental property",
    group: "utilities",
    group_label: "Utilities",
    is_loss: true,
    label: "Telephone",
    order: 27,
    value: "telephone",
  },
  {
    column_enabled: false,
    extra: "Utility paymnents made for gas, electric, water, etc",
    group: "utilities",
    group_label: "Utilities",
    is_loss: true,
    label: "Internet",
    order: 28,
    value: "internet",
  },
  {
    column_enabled: false,
    extra:
      "Monitor utility expenses such as water, sewer, gas, and electricity if the landlord is responsible for them.",
    group: "utilities",
    group_label: "Utilities",
    is_loss: true,
    label: "Utilities",
    order: 29,
    value: "utilities",
  },
  {
    column_enabled: false,
    extra: "Earned income during the operation of your rental property",
    group: "income",
    group_label: "Income",
    is_loss: false,
    label: "Income",
    order: 30,
    value: "income",
  },
  {
    column_enabled: false,
    extra:
      "Typically a non-refundable deposit made by your tenants to have a pet stay within your rental property",
    group: "income",
    is_loss: true,
    label: "Pet Deposit",
    order: 31,
    value: "pet_deposit",
  },
  {
    column_enabled: false,
    extra:
      "Record costs related to visiting and managing properties, such as mileage, parking, or airfare.",
    group: "travel",
    group_label: "Travel and Vehicle Expenses",
    is_loss: true,
    label: "Meals",
    order: 32,
    value: "meal",
  },
  {
    column_enabled: false,
    extra:
      "Record costs related to visiting and managing properties, such as mileage, parking, or airfare.",
    group: "travel",
    group_label: "Travel and Vehicle Expenses",
    is_loss: true,
    label: "Travel Expenses",
    order: 33,
    value: "travel",
  },
  {
    column_enabled: false,
    extra:
      "Record costs related to visiting and managing properties, such as mileage, parking, or airfare.",
    group: "travel",
    group_label: "Travel and Vehicle Expenses",
    is_loss: true,
    label: "Vehicle Expenses",
    order: 34,
    value: "vehicle",
  },
  {
    column_enabled: false,
    extra:
      "Record costs related to visiting and managing properties, such as mileage, parking, or airfare.",
    group: "travel",
    group_label: "Travel and Vehicle Expenses",
    is_loss: true,
    label: "Lodging",
    order: 35,
    value: "hotel",
  },
  {
    column_enabled: false,
    extra: "Interest charged to you for reasons other than your mortgage",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Other Interest",
    order: 36,
    value: "other_interest",
  },
  {
    column_enabled: false,
    extra: "Catch all for transactions that don't quite fit elsewhere",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Other",
    order: 37,
    value: "other",
  },
  {
    column_enabled: false,
    extra: "Cleaning fees incurred in the course of owning a rental property",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Cleaning",
    order: 38,
    value: "cleaning",
  },
  {
    column_enabled: false,
    extra: "Delivery fees incurred in the course of owning a rental property",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Delivery Fee",
    order: 39,
    value: "delivery_fee",
  },
  {
    column_enabled: false,
    extra: "Parking fees incurred while owning your rental property",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Parking",
    order: 40,
    value: "parking",
  },
  {
    column_enabled: false,
    extra:
      "Late fees incurred in the course of doing business and owning a rental property",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Late Fee",
    order: 41,
    value: "late_fee",
  },
  {
    column_enabled: false,
    extra:
      "Finance charges that may have been added to usage of your credit or loans",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Finance Charge",
    order: 42,
    value: "finance_charge",
  },
  {
    column_enabled: false,
    extra:
      "Tax payment made to your respective state for the operation of your business within that state. Typically on an annual basis.",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Franchise Tax Payment",
    order: 43,
    value: "ftb_payment",
  },
  {
    column_enabled: false,
    extra: "Housing Authority (HOA) monthly and/or annual payments made",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "HOA",
    order: 44,
    value: "hoa",
  },
  {
    column_enabled: false,
    extra:
      "Purchases made in support of the daily operation of your rental property",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Purchase",
    order: 45,
    value: "purchase",
  },
  {
    column_enabled: false,
    extra:
      "Wages paid to contractors or employees to maintain or otherwise support your rental property",
    group: "other",
    group_label: "Other",
    is_loss: true,
    label: "Wages",
    order: 46,
    value: "wage",
  },
];

export const fileTags = [
  { key: "file-tage-0", label: "Title/Escrow", value: FileTag.Title },
  { key: "file-tage-1", label: "Property Management", value: FileTag.PM },
  { key: "file-tage-2", label: "Appraisal", value: FileTag.Appraisal },
  { key: "file-tage-3", label: "Insurance", value: FileTag.Insurance },
  { key: "file-tage-4", label: "Maintenance", value: FileTag.Maintenance },
  { key: "file-tage-5", label: "Taxes", value: FileTag.Taxes },
  { key: "file-tage-6", label: "Lease", value: FileTag.Lease },
  { key: "file-tage-7", label: "Mortgage", value: FileTag.Mortgage },
  { key: "file-tage-8", label: "Contract", value: FileTag.Contract },
  { key: "file-tage-9", label: "Other", value: FileTag.Other },
];

function _findComponent({
  address_components,
}: {
  address_components: { short_name: string; types: string[] }[];
}) {
  return (str: string) => {
    return address_components.find((p) => {
      return p.types.indexOf(str) > -1;
    });
  };
}

function _reduceAmount(r: number, transaction: ITransaction) {
  const amount = transaction.amount || 0;
  const is_loss = transaction.is_loss ? -1 : 1;
  r += amount * is_loss;
  return r;
}

function _sumByName(arr: ITransaction[], name = "") {
  return arr
    .filter((t) => t.name.toLowerCase().includes(name))
    .reduce(_reduceAmount, 0);
}

export const calculate = {
  cashflow: (arr: ITransaction[]) => arr.reduce(_reduceAmount, 0),
  // Forecast amount over a duration in months
  forecasted: {
    vacancy: (percent = 0, due: number) => {
      return Math.floor(((percent / 100) * due - due) * -1);
    },
  },
  maintenance: (arr: ITransaction[]) => _sumByName(arr, "maintenance"),
  mortgage: (arr: ITransaction[]) => _sumByName(arr, "mortgage"),
  pmfee: (arr: ITransaction[]) => _sumByName(arr, "property_management_fee"),
  rent: (arr: ITransaction[]) => _sumByName(arr, "rent"),
  rentExpected: (arr: ITransaction[]) => {
    return arr
      .filter((t) => t.name.toLowerCase().includes("rent"))
      .filter((t) => typeof t.amount_expected === "number")
      .reduce((r, t) => {
        if (t.amount_expected) {
          r += t.amount_expected;
        }
        return r;
      }, 0);
  },
  vacancyIsOverThreshold: ({
    transactions,
    units,
  }: {
    transactions: ITransaction[];
    units: IUnit[];
  }): boolean => {
    const expectedRent = calculate.rentExpected(transactions);
    const rent = calculate.rent(transactions);

    const vacancies = units.reduce(
      (r: { count: number; total: number }, u: IUnit) => {
        const value = u.estimated_annual_vacancy || 0;
        r.total += value;
        r.count += value ? 1 : 0;
        return r;
      },
      { count: 0, total: 0 }
    );
    const averageVacancy = vacancies.total / vacancies.count;
    return (1 + averageVacancy / 100) * rent < expectedRent;
  },
};

export function daysRemaining(dayInUnix: number): {
  color: string;
  days: number;
  percentage: number;
} {
  let color = "active";
  const days = dayjs().diff(dayInUnix || new Date().getTime(), "days") * -1;

  const today = new Date().getTime();
  const thirtyDays = 1000 * 60 * 60 * 24 * 30;
  if (!dayInUnix) color = "color-1";
  else if (dayInUnix < thirtyDays + today) color = "red";
  else if (dayInUnix < thirtyDays * 2 + today) color = "orange";
  else if (dayInUnix < thirtyDays * 3 + today) color = "yellow";

  return {
    color,
    days,
    percentage: (days / 365) * 100,
  };
}

export function determineLabel(key: string): string {
  let match = transactionTypes.find((a) => a.value === key);
  return !match ? toTitleCase(key) : toTitleCase(match.label.replace("_", " "));
}

/** Convert Record Unix Timestamps to DayJS Class */
export function formatDatesToDayjs(values: any) {
  const params = { ...values };

  for (const key in params) {
    /** Step 1. Loop over keys for a date field match */
    if (_dateFields.includes(key)) {
      params[key] = dayjs(params[key]).valueOf();
    }
    if (params[key] === "removed") {
      delete params[key];
    }
  }

  /** Property Specific Formatting */
  if (params?.resource_id === ResourceId.Property) {
    params.date_purchased = params.date_purchased
      ? dayjs(params.date_purchased)
      : dayjs();
    params.insurance_end = params.insurance_end
      ? dayjs(params.insurance_end)
      : dayjs().add(1, "year");
    params.insurance_start = params.insurance_start
      ? dayjs(params.insurance_start)
      : dayjs();
  }

  /** System Specific Formatting */
  if (params?.resource_id === ResourceId.System && params.installed) {
    params.installed = params.installed ? dayjs(params.installed) : dayjs();
  }

  /** Transaction Specific Formatting */
  if (params?.resource_id === ResourceId.Transaction) {
    params.timestamp = params.timestamp ? dayjs(params.timestamp) : dayjs();
  }

  /** Unit Specific Formatting */
  if (params?.resource_id === ResourceId.Unit) {
    params.lease_end = params.lease_end
      ? dayjs(params.lease_end)
      : dayjs().add(1, "year");
    params.lease_start = params.lease_start
      ? dayjs(params.lease_start)
      : dayjs();
  }

  return params;
}

/** Convert Record Unix Timestamps from DayJS Class */
export function formatDatesFromDayjs(values: any) {
  const params = { ...values };

  const normalizeFields = ["name"];

  for (const key in params) {
    /** Step 1. Loop over keys for a date field match */
    if (_dateFields.includes(key) && params[key]) {
      if (params.clear_date) {
        params[key] = 0;
      } else {
        params[key] = dayjs(params[key]).valueOf();
      }
    }

    /** Step 2. Normalize certain fields */
    if (normalizeFields.includes(key)) {
      params[key] = Array.isArray(params[key]) ? params[key]?.[0] : params[key];
    }

    /** Step 3. Remove location if its empty object (edge case) */
    if (key === "location") {
      if (!Object.keys(params[key]).length) {
        delete params[key];
      }
    }
  }

  return params;
}

export function formatFileName(str: string): string {
  return str.replace(/[^a-zA-Z0-9.]/g, "-").toLowerCase();
}

export function formatName(member: IMember): string {
  let parts = [];
  if (!member) return "";
  if (member.given_name) parts.push(member.given_name);
  if (member.family_name) parts.push(member.family_name);
  if (parts.length === 0 && member.email) {
    parts.push(member.email);
  }
  if (parts.length === 0 && member.business_email) {
    parts.push(member.business_email);
  }
  return parts.join(" ");
}

/**
 * @description Pass an Array of Objects. Hone in (1) key, extract,
 * @continued remove duplicates and return array of unique values
 */
export function getUniqueValues(arr = [], key = "resource_name") {
  const distilled = arr.reduce(
    (r, property) => ({
      ...r,
      [property[key]]: property[key],
    }),
    {}
  );

  return Object.values(distilled).filter((value) => value);
}

export function googlePlaceToObject({
  address_components,
  formatted_address,
  geometry,
  place_id,
}: any) {
  const component = _findComponent({ address_components });
  const city1 = component("locality")?.short_name;
  const city2 = component("administrative_area_level_2")?.short_name;
  const city3 = component("political")?.short_name;
  const county = component("administrative_area_level_1")?.short_name;
  const country = component("country")?.short_name;

  return {
    address: [
      component("street_number")?.short_name,
      component("route")?.short_name,
    ]
      .filter((str) => str)
      .join(" "),
    city: city1 || city2 || city3,
    county: city2 || county,
    country,
    formatted_address,
    location: {
      lat: geometry.location.lat(),
      lon: geometry.location.lng(),
    },
    place_id,
    state: component("administrative_area_level_1")?.short_name || "",
    zip_code: component("postal_code")?.short_name,
  };
}

export function keygen() {
  let d =
    new Date().getTime() + Math.floor(Math.random() * 1000000000000000000);
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function removeDuplicates(arr = [], key = "resource_name") {
  return Object.values(
    (arr || []).reduce((r, props) => {
      const subkey = props[key];
      r[subkey] = props;

      return r;
    }, {})
  );
}

export const randomResourceName = (resource_id: ResourceId) => {
  return `${resource_id}-${keygen()}`;
};

export const sortLists = ({ baseline = [], data = [] }: ISortLists) => {
  return [...data].sort((a: IStringObject, b: IStringObject) => {
    let indexA = baseline.indexOf(a?.value);
    let indexB = baseline.indexOf(b?.value);
    if (indexA === -1) indexA = data.length + baseline.indexOf(a?.value);
    if (indexB === -1) indexB = data.length + baseline.indexOf(b?.value);

    return indexA - indexB;
  });
};

export const testimonialCategories = [
  {
    key: "testimonial-category-0",
    label: "Data Management",
    value: TestimonialCategory.DataManagement,
  },
  {
    key: "testimonial-category-1",
    label: "Documentation & Storage Management",
    value: TestimonialCategory.DocumentationStorage,
  },
  {
    key: "testimonial-category-2",
    label: "Operational Efficiency",
    value: TestimonialCategory.OperationalEfficiency,
  },
  {
    key: "testimonial-category-4",
    label: "Overall",
    value: TestimonialCategory.Overall,
  },
  {
    key: "testimonial-category-5",
    label: "Real Estate Scalability",
    value: TestimonialCategory.RealEstateScalability,
  },
  {
    key: "testimonial-category-6",
    label: "Reporting Analytics",
    value: TestimonialCategory.ReportingAnalytics,
  },
  {
    key: "testimonial-category-7",
    label: "Tax Reporting",
    value: TestimonialCategory.TaxReporting,
  },
  {
    key: "testimonial-category-3",
    label: "Other",
    value: TestimonialCategory.Other,
  },
];

export function toTitleCase(str = "") {
  return !str
    ? ""
    : str.replace(/[^a-zA-Z0-9]/g, " ").replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function transactionOptions(options: any) {
  return options.filter((option: any) => {
    return option.value !== "maintenance_repair";
  });
}