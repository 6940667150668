/** Redux */
import * as types from "./types";

/** Redux */
import { setActiveUnitAction } from "./unit";

/** Types */
import type { IAppDispatch } from "../configureStore";

import type {
  IAnyObject,
  IProperty,
  IPropertyValuation,
  ISearchProperty,
} from "../../types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

export function createPropertyAction(property: IProperty) {
  return (dispatch: IAppDispatch) => {
    dispatch(setActivePropertyAction({}));
    dispatch(setActiveUnitAction({}));
    sendMessage(dispatch)({
      action: "property",
      method: "PUT",
      path: "property",
      property,
      type: types.CREATE_PROPERTY_SUCCESS,
    });
  };
}

export function deletePropertyAction(property: IProperty) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "property",
      method: "DELETE",
      path: "property",
      property,
      type: types.DELETE_PROPERTY_SUCCESS,
    });
  };
}

export function getPropertyAction(property: IProperty) {
  return (dispatch: IAppDispatch, getState: any) => {
    sendMessage(dispatch)({
      action: "property",
      method: "GET",
      path: "property",
      property,
      settings: getState().setting.details,
      type: types.GET_PROPERTY_SUCCESS,
    });
  };
}

export function getPropertyMortgageAction(search: ISearchProperty) {
  return (dispatch: IAppDispatch, getState: any) => {
    sendMessage(dispatch)({
      action: "property",
      method: "GET",
      path: "property/mortgage",
      search,
      type: types.GET_PROPERTY_MORTGAGE_SUCCESS,
    });
  };
}

export function searchPropertiesAction(search: ISearchProperty) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "property",
      method: "GET",
      path: "property/search",
      search,
      type: types.SEARCH_PROPERTIES_SUCCESS,
    });
  };
}

export function getPropertyValuationAction(search: IPropertyValuation) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "property",
      method: "GET",
      path: "property/valuation",
      search,
      type: types.UPDATE_PROPERTY_SUCCESS,
    });
  };
}

export function setActivePropertyAction(property: IProperty) {
  return (dispatch: IAppDispatch) => {
    dispatch({
      property,
      type: types.SET_ACTIVE_PROPERTY_SUCCESS,
    });
  };
}

export function updatePropertyAction(property: IProperty) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "property",
      method: "POST",
      path: "property",
      property,
      type: types.UPDATE_PROPERTY_SUCCESS,
    });
  };
}
