/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

const details = createReducer(initialState.property.details, (builder) => {
  builder
    .addCase(createAction(types.ARCHIVE_PROPERTY_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Property);
    })
    .addCase(createAction(types.CREATE_PROPERTY_SUCCESS), (state, action) => {
      return action.property;
    })
    .addCase(createAction(types.DELETE_PROPERTY_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Property);
    })
    .addCase(createAction(types.GET_PROPERTY_SUCCESS), (state, action) => {
      return action.property;
    })
    .addCase(
      createAction(types.SET_ACTIVE_PROPERTY_SUCCESS),
      (state, action) => {
        return action.property || {};
      }
    )
    .addCase(createAction(types.TOGGLE_FLOW_SUCCESS), (state, action) => {
      return redux.single.applyFlowControl(state, action, ResourceId.Property);
    })
    .addCase(createAction(types.UPDATE_PROPERTY_SUCCESS), (state, action) => {
      return redux.single.updateMatchCase(state, action, ResourceId.Property);
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.property.list, (builder) => {
  builder
    .addCase(createAction(types.ARCHIVE_PROPERTY_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, ResourceId.Property);
    })
    .addCase(createAction(types.CREATE_PROPERTY_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, ResourceId.Property);
    })
    .addCase(createAction(types.DELETE_PROPERTY_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, ResourceId.Property);
    })
    .addCase(createAction(types.SEARCH_PROPERTIES_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.UPDATE_PROPERTY_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, ResourceId.Property);
    })
    .addDefaultCase((state) => state);
});

const mortgage = createReducer(initialState.property.mortgage, (builder) => {
  builder
    .addCase(
      createAction(types.GET_PROPERTY_MORTGAGE_SUCCESS),
      (state, action) => {
        return action.results;
      }
    )
    .addDefaultCase((state) => state);
});

const property = combineReducers({ details, list, mortgage });

export default property;
