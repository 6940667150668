/** Vendor */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

const metrics = createReducer(initialState.metrics, (builder) => {
  /** Standard Metrics Mgmt */
  builder
    .addCase(
      createAction(types.CREATE_METRIC_TEMPLATE_SUCCESS),
      (state, action) => ({
        ...state,
        template: {
          ...state.template,
          details: action.metric,
          list: redux.single.addResources(
            state.template.list,
            action,
            ResourceId.MetricsTemplate
          ),
        },
      })
    )
    .addCase(
      createAction(types.DELETE_METRIC_TEMPLATE_SUCCESS),
      (state, action) => ({
        ...state,
        template: {
          ...state.template,
          details: redux.single.removeMatchCase(
            state.template.details,
            action,
            ResourceId.Metric
          ),
          list: redux.multi.removeMatchCase(
            state.template.list,
            action,
            ResourceId.MetricsTemplate
          ),
        },
      })
    )
    .addCase(
      createAction(types.GET_METRIC_TEMPLATE_SUCCESS),
      (state, action) => ({
        ...state,
        template: {
          ...state.template,
          details: action.metric,
        },
      })
    )
    .addCase(
      createAction(types.SEARCH_METRIC_TEMPLATES_SUCCESS),
      (state, action) => ({
        ...state,
        template: {
          ...state.template,
          list: redux.joinOrOverwrite(state.template.list, action),
        },
      })
    )
    .addCase(
      createAction(types.UPDATE_METRIC_TEMPLATE_SUCCESS),
      (state, action) => ({
        ...state,
        template: {
          details: redux.single.updateMatchCase(
            state.template.details,
            action,
            ResourceId.MetricsTemplate
          ),
          list: redux.multi.updateMatchCase(
            state.template.list,
            action,
            ResourceId.MetricsTemplate
          ),
        },
      })
    );

  /** Portfolio Metrics */
  builder
    .addCase(
      createAction(types.GET_PORTFOLIO_METRICS_SUCCESS),
      (state, action) => {
        return {
          ...state,
          portfolio: action.portfolio,
        };
      }
    )
    .addCase(
      createAction(types.GET_PROPERTIES_METRICS_SUCCESS),
      (state, action) => {
        return {
          ...state,
          property:
            action.results.find((metric) => {
              return metric.property === state.property?.property;
            }) || state.property,
          properties: redux.joinOrOverwrite(state.properties, action),
        };
      }
    )
    .addCase(
      createAction(types.GET_PROPERTY_METRICS_SUCCESS),
      (state, action) => {
        return {
          ...state,
          property: action.property,
        };
      }
    )
    .addCase(
      createAction(types.GET_PROPERTY_UNITS_METRICS_SUCCESS),
      (state, action) => {
        return {
          ...state,
          units: redux.joinOrOverwrite(state.units, action),
        };
      }
    )
    .addDefaultCase((state) => state);
});

export default metrics;
