/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Redux Types */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Enums */
import { MemberStatus } from "../../types";

const auth = createReducer(initialState.auth, (builder) => {
  builder
    .addCase(createAction(types.RESET_AUTH_SUCCESS), () => {
      return {
        is_admin: false,
        is_intro_complete: false,
        status: MemberStatus.Unsubscribed,
      };
    })
    .addCase(createAction(types.UPDATE_AUTH_SUCCESS), (state, action) => {
      return {
        ...state,
        ...action.auth,
      };
    })
    .addDefaultCase((state) => state);
});

export default auth;
