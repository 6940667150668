import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from './ga4'; // Adjust path if necessary

const PageTracker: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return <>{children}</>;
};

export default PageTracker;