/** Vendors */
import accounting from "accounting";
import axios from "axios";

/** Custom Hooks */
import storageMethods from "../../hooks/useStorage";
const storage = storageMethods();

/** Enums */
import { AsyncStorageKey } from "../../types";

accounting.settings.currency.format = {
  pos: "%s%v",
  neg: "- %s%v",
  zero: "%s%v",
};

axios.defaults.baseURL = import.meta.env.VITE_REACT_APP_API_ENDPOINT;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.response.use(
  function (response) {
    if (!Object.prototype.hasOwnProperty.call(response, "data")) {
      return response;
    }
    return response.data;
  },
  function (error) {
    console.log("Error Status Code: ", error?.response?.status, error?.message);
    return Promise.reject({ error: error.message });
  }
);

(async function () {
  /** Setup Property Transaction Column Defaults */
  const columns = await storage.getItem({ key: AsyncStorageKey.Setting });

  if (!columns?.transaction_table_columns) {
    const value = {
      transaction_table_columns: {
        /** Standand */
        cashflow: true,
        maintenance: true,
        mortgage: true,
        property_management_fee: true,
        rent: true,
        /** Non Standard */
        advertising: false,
        capital_improvement: false,
        commission: false,
        depreciation: false,
        finance_charge: false,
        insurance: {
          earthquake_insurance: false,
          flood_insurance: false,
          hazard_insurance: false,
          hurricane_insurance: false,
          insurance: false,
          landlord_insurance: false,
        },
        hoa: false,
        internet: false,
        legal_professional_fee: false,
        office_supplies: false,
        property_tax: false,
        repair: false,
        travel: false,
        utilities: false,
      },
    };

    storage.mergeItem({ key: AsyncStorageKey.Setting, value });
  }
})();
