/** Fontawesome */
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBars,
  faChevronLeft,
  faChevronCircleRight,
  faCircleCheck,
  faEnvelope,
  faEquals,
  faFilter,
  faFilterCircleXmark,
  faFilterSlash,
  faHouseDay,
  faIndustryWindows,
  faPenToSquare,
} from "@fortawesome/sharp-regular-svg-icons";

library.add(
  faBars,
  faChevronCircleRight,
  faChevronLeft,
  faCircleCheck,
  faEnvelope,
  faEquals,
  faFilter,
  faFilterCircleXmark,
  faFilterSlash,
  faHouseDay,
  faIndustryWindows,
  faPenToSquare
);
