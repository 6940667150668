/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

const device = createReducer(initialState.device, (builder) => {
  builder
    .addCase(createAction(types.TOGGLE_TOUR_SUCCESS), (state, action) => {
      return { ...state, tour: action.details };
    })
    .addCase(createAction(types.UPDATE_DEVICE_DETAILS), (state, action) => {
      return { ...state, ...action.device };
    })
    .addDefaultCase((state) => state);
});

export default device;
