/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const details = createReducer(initialState.affiliate.details, (builder) => {
  builder
    .addCase(createAction(types.DELETE_AFFILIATE_SUCCESS), () => ({}))
    .addCase(createAction(types.CREATE_AFFILIATE_SUCCESS), (state, action) => {
      return action.affiliate;
    })
    .addCase(createAction(types.GET_AFFILIATE_SUCCESS), (state, action) => {
      return action.affiliate;
    })
    .addCase(createAction(types.UPDATE_AFFILIATE_SUCCESS), (state, action) => {
      return { ...state, ...action.affiliate };
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.affiliate.list, (builder) => {
  builder
    .addCase(createAction(types.DELETE_AFFILIATE_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, "affiliate");
    })
    .addCase(createAction(types.CREATE_AFFILIATE_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, "affiliate");
    })
    .addCase(createAction(types.SEARCH_AFFILIATES_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.UPDATE_AFFILIATE_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, "affiliate");
    })
    .addDefaultCase((state) => state);
});

const affiliate = combineReducers({ details, list });

export default affiliate;
