/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const list = createReducer(initialState.tag.list, (builder) => {
  builder
    .addCase(createAction(types.SEARCH_TAGS_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addDefaultCase((state) => state);
});

const tags = combineReducers({ list });

export default tags;
