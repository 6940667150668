/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { AnyAction, IRootState } from "@redux/configureStore";

const video = createReducer(initialState.video, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_VIDEO_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return {
          ...state,
          details: action.video,
          list: redux.addResources(state.list, action, "video"),
        };
      }
    )
    .addCase(
      createAction(types.DELETE_VIDEO_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return {
          ...state,
          details: redux.single.removeMatchCase(state.details, action, "video"),
          list: redux.removeMatchCase(state.list, action, "video"),
        };
      }
    )
    .addCase(
      createAction(types.GET_VIDEO_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return { ...state, details: action.video };
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_VIDEO_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return { ...state, details: action.video };
      }
    )
    .addCase(
      createAction(types.SEARCH_VIDEO_CATEGORIES_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return {
          ...state,
          category: { ...state.category, list: action.categories },
        };
      }
    )
    .addCase(
      createAction(types.SEARCH_VIDEOS_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return { ...state, list: redux.joinOrOverwrite(state.list, action) };
      }
    )
    .addCase(
      createAction(types.UPDATE_VIDEO_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return {
          ...state,
          details: redux.single.updateMatchCase(state.details, action, "video"),
          list: redux.updateMatchCase(state.list, action, "video"),
        };
      }
    )
    .addDefaultCase((state) => state);
});

export default video;
