import React, { useEffect, useRef, useState } from "react";

/** Vendors */
import { Modal } from "antd";

/** Custom Components */
import AppLoading from "../loading/App";

/** Custom Hooks */
import useStorage from "@hooks/useStorage";

/** Enums */
import { AsyncStorageKey } from "types";

export default function LegalBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  const [hasAccepted, setHasAccepted] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const storage = useStorage();
  const scriptRef = useRef();

  const date = import.meta.env.VITE_REACT_APP_TERMS_OF_USE_DATE as string;

  /** Step 1. Get state of acceptance */
  useEffect(() => {
    storage.getItem({ key: AsyncStorageKey.Legal }).then((response) => {
      if (response?.app?.timestamp && date === response?.app?.date) {
        setHasAccepted(true);
      } else setShow(true);
    });
  }, []);

  /** Step 2. Trigger the load of termly based on showing modal or not */
  useEffect(() => {
    if (!show) return;
    (function (d, s, id) {
      const tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        scriptRef.current = d.getElementById(id);
        return;
      }
      scriptRef.current = d.createElement(s);
      scriptRef.current.id = id;
      scriptRef.current.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(scriptRef.current, tjs);
      window.scrollTo(0, 0);
    })(document, "script", "termly-jssdk");

    return () => scriptRef?.current?.remove();
  }, [show]);

  /** Step 3. Orchestrate accept and deny input */
  const actions = {
    accept: async function () {
      await storage.mergeItem({
        key: AsyncStorageKey.Legal,
        value: { app: { date, timestamp: new Date().getTime() } },
      });
      setHasAccepted(true);
    },
    deny: () => {
      window.location.href = "https://www.tractic.io/contact";
    },
  };

  if (!hasAccepted) {
    return (
      <React.Fragment>
        <Modal
          closable={false}
          forceRender={true}
          maskClosable={false}
          okText="I Accept"
          cancelText="I Do Not Accept"
          onOk={actions.accept}
          onCancel={actions.deny}
          open={show}
          styles={{
            body: { height: "calc(80vh - 120px)", overflowY: "auto" },
          }}
          title="Terms and Conditions Disclaimer"
          width="80%"
        >
          <div
            className="h-100"
            data-id={String(import.meta.env.VITE_REACT_APP_TERMS_OF_USE_ID)}
            data-name="termly-embed"
            data-type="iframe"
            name="termly-embed"
          />
        </Modal>
        <AppLoading />
      </React.Fragment>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}
