import React from "react";

/** Vendors */
import { Button, Card, Flex, Spin, Typography } from "antd";

/** Brand */
import Logo from "@dist/img/brand/tractic-logo-text-1024.png";

/** Custom Hooks */
import useDevice from "@hooks/useDevice";

/** Custom CSS */
import "./app.css";

/** Enums */
import { MaintenanceStatus } from "types";

const background =
  "https://assets.tractic.io?lvl1=img&lvl2=article&lvl3=pig-background-1.png";

/** @description Initial Loading Screen Before WebSocket Connection */
function AppLoadingScreen({ status }) {
  const { isMobile } = useDevice();

  return (
    <div
      className="w-100 h-100 loading text-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      <Card className="m-auto">
        <Flex align="center" gap={24} vertical>
          <img
            alt="TRACTIC"
            src={Logo}
            style={{ width: isMobile ? "60vw" : "20vw" }}
          />
          <div className="text-center">
            {status === MaintenanceStatus.Unavailable ? (
              <Flex gap={24} vertical>
                <Typography.Title level={4}>
                  TRACTIC's platform is currently under going maintenance.
                  <br />
                  Please check back soon. Thank you for your patience.
                </Typography.Title>
                <Button
                  href="https://www.tractic.io"
                  size="large"
                  type="primary"
                >
                  Back to main site
                </Button>
              </Flex>
            ) : (
              <Spin size="large" style={{ color: "var(--color1)" }} />
            )}
          </div>
        </Flex>
      </Card>
    </div>
  );
}

export default AppLoadingScreen;
