/** Vendors */
import { Hub } from "aws-amplify/utils";

/** Redux Types */
import * as types from "../actions/types";

/** Types */
import type { Middleware } from "redux";
/**
 * @description Control Active Page URLs from Serverside
 */
const routerMiddleware: Middleware = () => {
  return (next) => (action) => {
    switch (action.type) {
      case types.PAGE_REDIRECT_SUCCESS:
        Hub.dispatch("router", {
          data: { path: action.path },
          event: "success",
        });
        break;
      case types.SITE_REDIRECT_SUCCESS:
        window.location.replace(action.redirect_url);
        break;
    }
    next(action);
  };
};

export default routerMiddleware;
