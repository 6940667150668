/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

const flow = createReducer(initialState.flow, (builder) => {
  builder
    .addCase(createAction(types.CREATE_PROPERTY_SUCCESS), (state, action) => {
      return { ...state, is_active: true, details: action.property };
    })
    .addCase(createAction(types.TOGGLE_FLOW_SUCCESS), (state, action) => {
      return { ...state, is_active: !state.is_active, details: action.details };
    })
    .addDefaultCase((state) => state);
});

export default flow;
