/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";

import type {
  IAnyObject,
  IMember,
  ISearchMembers,
  IStripeSearchSubscriptions,
  IStripeSubscription,
} from "../../types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

export function getMemberAction(member: IMember) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      member,
      method: "GET",
      path: "member",
      type: types.GET_MEMBER_SUCCESS,
    });
  };
}

export function searchMembersAction(search: ISearchMembers) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      method: "GET",
      path: "member/search",
      search,
      type: types.SEARCH_MEMBERS_SUCCESS,
    });
  };
}

export function setActiveMemberAction(member: IMember) {
  return (dispatch: IAppDispatch) => {
    dispatch({
      member,
      type: types.SET_ACTIVE_MEMBER_SUCCESS,
    });
  };
}

export function updateMemberAction(member: IMember) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      member,
      method: "POST",
      path: "member",
      type: types.UPDATE_MEMBER_SUCCESS,
    });
  };
}

//Stripe Subscription Actions.
export function createSubscriptionAction(details: any) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      details,
      action: "stripe",
      method: "PUT",
      path: "stripe/subscription",
      type: types.STRIPE_SUBSCRIPTION_CREATE,
    });
  };
}

export function cancelSubscriptionAction(subscription: any) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "stripe",
      method: "DELETE",
      path: "stripe/subscription",
      subscription,
      type: types.STRIPE_SUBSCRIPTION_CANCEL,
    });
  };
}

export function retryInvoiceWithNewPaymentMethodAction(details: any) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "stripe",
      method: "RETRY",
      details,
      path: "stripe/subscription/retry",
      type: types.STRIPE_SUBSCRIPTION_RETRY,
    });
  };
}

export function downgradeSubscriptionAction(
  subscription: Partial<IStripeSubscription>
) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "stripe",
      method: "POST",
      path: "stripe/subscription/downgrade",
      subscription,
      type: types.STRIPE_SUBSCRIPTION_DOWNGRADE,
    });
  };
}

export function searchCardsAction(search = {}) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "stripe",
      method: "GET",
      path: "stripe/card/search",
      search,
      type: types.STRIPE_CARD_SEARCH,
    });
  };
}

export function searchInvoicesAction(search = {}) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "stripe",
      method: "GET",
      path: "stripe/invoice/search",
      search,
      type: types.STRIPE_INVOICE_SEARCH,
    });
  };
}

export function searchSubscriptionsAction(search: IStripeSearchSubscriptions) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "stripe",
      method: "GET",
      path: "stripe/subscription/search",
      search,
      type: types.STRIPE_SUBSCRIPTION_SEARCH,
    });
  };
}

export function upgradeSubscriptionAction(
  subscription: Partial<IStripeSubscription>
) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "stripe",
      method: "POST",
      path: "stripe/subscription/upgrade",
      subscription,
      type: types.STRIPE_SUBSCRIPTION_UPGRADE,
    });
  };
}

// /** Member Groups */
// export function createGroupAction(group: IGroup) {
//   return (dispatch: IAppDispatch) => {
//     sendMessage(dispatch)({
//       action: "member",
//       group,
//       method: "PUT",
//       path: "member/group",
//       type: types.GET_GROUP_SUCCESS,
//     });
//   };
// }

// export function getGroupAction(group: IGroup) {
//   return (dispatch: IAppDispatch) => {
//     sendMessage(dispatch)({
//       action: "member",
//       group,
//       method: "GET",
//       path: "member/group",
//       type: types.GET_GROUP_SUCCESS,
//     });
//   };
// }

// export function searchGroupsAction(search: ISearchGroups) {
//   return (dispatch: IAppDispatch) => {
//     sendMessage(dispatch)({
//       action: "member",
//       method: "GET",
//       path: "member/group/search",
//       search,
//       type: types.SEARCH_GROUPS_SUCCESS,
//     });
//   };
// }

// export function setActiveGroupAction(group: IGroup) {
//   return (dispatch: IAppDispatch) => {
//     dispatch({
//       group,
//       type: types.SET_ACTIVE_GROUP_SUCCESS,
//     });
//   };
// }

// export function updateGroupAction(group: IGroup) {
//   return (dispatch: IAppDispatch) => {
//     sendMessage(dispatch)({
//       action: "member",
//       group,
//       method: "POST",
//       path: "member/group",
//       type: types.UPDATE_GROUP_SUCCESS,
//     });
//   };
// }
