/** Vendors */
import axios from "axios";
import history from "../../history";

/** Redux */
import * as types from "../actions/types";

/** Types */
import type { Middleware } from "redux";

const socketMiddleware: Middleware = (store: any) => {
  const worker = new Worker("../workers/socket.js");

  worker.addEventListener("message", (message: any) => {
    switch (message.data.type) {
      case "SOCKET_CONNECTION_CLOSED":
        console.log("Socket Closed");
        store.dispatch({ type: types.SOCKET_REFRESH });
        break;
      case "SOCKET_CONNECTION_DATA":
        if (
          !message.data.payload.message?.includes("Internal") &&
          !message.data.payload.message?.includes("Failed") &&
          !!message.data.payload.type
        ) {
          store.dispatch(message.data.payload);
        }
        break;
      case "SOCKET_CONNECTION_ERROR":
        console.log("Socket Error");
        store.dispatch({ type: types.SOCKET_DISCONNECTED });
        break;
      case "SOCKET_CONNECTION_SUCCESS":
        console.log("Socket Connected");
        store.dispatch({ type: types.SOCKET_CONNECTED });
        break;
      default:
        console.log("Unhandled Worker Message: ", message);
    }
  });

  return (next: any) => (action: any) => {
    switch (action.type) {
      case types.INITIALIZE_SOCKET_CONNECTION:
        const idToken =
          `${axios.defaults.headers.common?.Authorization}`?.split(" ")?.[1];

        if (!worker) {
          store.dispatch({ type: types.SOCKET_DISCONNECTED });
        } else {
          console.log(action.current_status);
          worker.postMessage({
            endpoint: import.meta.env.VITE_REACT_APP_SOCKET_URL,
            idToken,
            path: history.location.pathname,
            search: history.location.search,
            startup: action.current_status !== "refresh",
            type: types.INITIALIZE_SOCKET_CONNECTION,
          });
        }
        break;
      case types.SOCKET_SEND_MESSAGE:
        if (!worker) {
          store.dispatch({ type: types.SOCKET_DISCONNECTED });
        } else {
          worker?.postMessage(action);
        }
        break;
    }
    return next(action);
  };
};

export default socketMiddleware;
