/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

const details = createReducer(initialState.notification.details, (builder) => {
  builder
    .addCase(
      createAction(types.DELETE_NOTIFICATION_SUCCESS),
      (state, action) => {
        return redux.single.removeMatchCase(
          state,
          action,
          ResourceId.Notification
        );
      }
    )
    .addCase(
      createAction(types.CREATE_NOTIFICATION_SUCCESS),
      (state, action) => {
        return action.notification || {};
      }
    )
    .addCase(createAction(types.GET_NOTIFICATION_SUCCESS), (state, action) => {
      return action.notification || {};
    })
    .addCase(
      createAction(types.UPDATE_NOTIFICATION_SUCCESS),
      (state, action) => {
        return redux.single.updateMatchCase(
          state,
          action,
          ResourceId.Notification
        );
      }
    )
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.notification.list, (builder) => {
  builder
    .addCase(
      createAction(types.DELETE_NOTIFICATION_SUCCESS),
      (state, action) => {
        return redux.single.removeMatchCase(
          state,
          action,
          ResourceId.Notification
        );
      }
    )
    .addCase(
      createAction(types.CREATE_NOTIFICATION_SUCCESS),
      (state, action) => {
        return redux.single.addResource(state, action, ResourceId.Notification);
      }
    )
    .addCase(
      createAction(types.SEARCH_NOTIFICATIONS_SUCCESS),
      (state, action) => {
        return redux.joinOrOverwrite(state, action);
      }
    )
    .addCase(
      createAction(types.RESET_NOTIFICATIONS_SUCCESS),
      (state, action) => {
        return redux.resetResources(state, action);
      }
    )
    .addCase(
      createAction(types.UPDATE_NOTIFICATION_SUCCESS),
      (state, action) => {
        return redux.multi.updateMatchCase(
          state,
          action,
          ResourceId.Notification
        );
      }
    )
    .addDefaultCase((state) => state);
});

const templateDetails = createReducer(
  initialState.notification.template.details,
  (builder) => {
    builder
      .addCase(
        createAction(types.CREATE_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return action.template || {};
        }
      )
      .addCase(
        createAction(types.DELETE_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return redux.single.removeMatchCase(state, action, "template");
        }
      )
      .addCase(
        createAction(types.GET_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return action.template || {};
        }
      )
      .addCase(
        createAction(types.SET_ACTIVE_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return action.template || {};
        }
      )
      .addCase(
        createAction(types.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return redux.single.updateMatchCase(state, action, "template");
        }
      )
      .addDefaultCase((state) => state);
  }
);

const templateList = createReducer(
  initialState.notification.template.list,
  (builder) => {
    builder
      .addCase(
        createAction(types.CREATE_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return redux.single.addResource(state, action, "template");
        }
      )
      .addCase(
        createAction(types.DELETE_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return redux.multi.removeMatchCase(state, action, "template");
        }
      )
      .addCase(
        createAction(types.GET_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return redux.multi.updateMatchCase(state, action, "template");
        }
      )
      .addCase(
        createAction(types.SEARCH_NOTIFICATION_TEMPLATES_SUCCESS),
        (state, action) => {
          return redux.joinOrOverwrite(state, action);
        }
      )
      .addCase(
        createAction(types.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS),
        (state, action) => {
          return redux.multi.updateMatchCase(state, action, "template");
        }
      )
      .addDefaultCase((state) => state);
  }
);

const template = combineReducers({
  details: templateDetails,
  list: templateList,
});

const notification = combineReducers({
  details,
  list,
  template,
});

export default notification;
