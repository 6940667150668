import { createContext } from "react";

/** Custom Hooks */
import { useAppSelector } from "@hooks/useRedux";
import useSubscription from "@hooks/useSubscription";

export const AuthContext = createContext(null);

function AuthContextWrapper({ children }) {
  const { contacts, member, property } = useAppSelector((state) => ({
    contacts: state.contact.list.filtered,
    member: state.member.details,
    property: state.property.details,
  }));

  const subscription = useSubscription({ contacts, member, property });

  return (
    <AuthContext.Provider value={subscription}>{children}</AuthContext.Provider>
  );
}

export default AuthContextWrapper;
