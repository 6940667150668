/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

const details = createReducer(initialState.ticket.details, (builder) => {
  builder
    .addCase(createAction(types.DELETE_TICKET_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Ticket);
    })
    .addCase(createAction(types.CREATE_TICKET_SUCCESS), (state, action) => {
      return action.ticket || {};
    })
    .addCase(createAction(types.GET_TICKET_SUCCESS), (state, action) => {
      return action.ticket || {};
    })
    .addCase(createAction(types.SET_ACTIVE_TICKET_SUCCESS), (state, action) => {
      return action.ticket || {};
    })
    .addCase(createAction(types.TOGGLE_TICKET_MODAL), (state, action) => {
      return action.details || {};
    })
    .addCase(createAction(types.UPDATE_TICKET_SUCCESS), (state, action) => {
      return redux.single.updateMatchCase(state, action, ResourceId.Ticket);
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.ticket.list, (builder) => {
  builder
    .addCase(createAction(types.DELETE_TICKET_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Ticket);
    })
    .addCase(createAction(types.CREATE_TICKET_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, ResourceId.Ticket);
    })
    .addCase(createAction(types.SEARCH_TICKETS_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.UPDATE_TICKET_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, ResourceId.Ticket);
    })
    .addDefaultCase((state) => state);
});

const note = createReducer(initialState.ticket.note, (builder) => {
  builder
    .addCase(
      createAction(types.DELETE_TICKET_NOTE_SUCCESS),
      (state, action) => {
        return redux.single.removeMatchCase(
          state,
          action,
          ResourceId.TicketNote
        );
      }
    )
    .addCase(
      createAction(types.CREATE_TICKET_NOTE_SUCCESS),
      (state, action) => {
        return action.ticket || {};
      }
    )
    .addCase(createAction(types.GET_TICKET_NOTE_SUCCESS), (state, action) => {
      return action.ticket || {};
    })
    .addCase(
      createAction(types.SET_ACTIVE_TICKET_NOTE_SUCCESS),
      (state, action) => {
        return action.ticket || {};
      }
    )
    .addCase(
      createAction(types.UPDATE_TICKET_NOTE_SUCCESS),
      (state, action) => {
        return redux.single.updateMatchCase(
          state,
          action,
          ResourceId.TicketNote
        );
      }
    )
    .addDefaultCase((state) => state);
});

const notes = createReducer(initialState.ticket.notes, (builder) => {
  builder
    .addCase(
      createAction(types.DELETE_TICKET_NOTE_SUCCESS),
      (state, action) => {
        return redux.single.removeMatchCase(
          state,
          action,
          ResourceId.TicketNote
        );
      }
    )
    .addCase(
      createAction(types.CREATE_TICKET_NOTE_SUCCESS),
      (state, action) => {
        return redux.single.addResource(state, action, ResourceId.TicketNote);
      }
    )
    .addCase(
      createAction(types.SEARCH_TICKET_NOTES_SUCCESS),
      (state, action) => {
        return redux.joinOrOverwrite(state, action);
      }
    )
    .addCase(
      createAction(types.UPDATE_TICKET_NOTE_SUCCESS),
      (state, action) => {
        return redux.multi.updateMatchCase(
          state,
          action,
          ResourceId.TicketNote
        );
      }
    )
    .addDefaultCase((state) => state);
});

const ticket = combineReducers({
  details,
  list,
  note,
  notes,
});

export default ticket;
