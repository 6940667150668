/** Vendors */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Mass Upload Pending Tags */
const dropped = createReducer(initialState.file.dropped, (builder) => {
  builder
    .addCase(createAction(types.DELETE_FILE_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, "file", "key");
    })
    .addCase(createAction(types.RESET_DROPPED_FILES_SUCCESS), (state) => {
      return redux.resetResources(state);
    })
    .addCase(createAction(types.TOGGLE_FILE_MODAL), (state, action) => ({
      all: action?.dropped || [],
      filtered: action?.dropped || [],
    }))
    /** On Update, Remove from Display */
    .addCase(createAction(types.UPDATE_FILE_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, "file", "key");
    })
    .addDefaultCase((state) => state);
});

/** File Actions */
const list = createReducer(initialState.file.list, (builder) => {
  builder
    .addCase(createAction(types.DELETE_FILE_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, "file", "key");
    })
    .addCase(createAction(types.RESET_FILE_FILTER_SUCCESS), (state) => {
      return redux.resetResources(state);
    })
    .addCase(createAction(types.RESET_FILES_SUCCESS), () => ({
      all: [],
      filtered: [],
    }))
    .addCase(createAction(types.SEARCH_FILES_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.SEARCH_FILE_PENDING), (state) => {
      return redux.startLoadingReportsList(state);
    })
    .addCase(createAction(types.SET_ACTIVE_PROPERTY_SUCCESS), () => ({
      all: [],
      filtered: [],
    }))
    .addCase(createAction(types.UPDATE_FILE_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, "file", "key");
    })
    .addDefaultCase((state) => state);
});

const file = combineReducers({ dropped, list });

export default file;
