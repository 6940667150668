import React from "react";

/** Vendors */
import { Layout } from "antd";

/** Custom Components */
import ContentLoading from "./Content";
import SidebarLoading from "./Sidebar";

function ViewLoading() {
  return (
    <Layout
      hasSider={true}
      style={{
        height: "100vh",
        margin: "auto",
      }}
    >
      <SidebarLoading />
      <Layout className="site-layout p-4">
        <ContentLoading />
      </Layout>
    </Layout>
  );
}

export default ViewLoading;
