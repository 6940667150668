export enum AffiliateStatus {
  Active = "active",
  Pending = "pending",
  Denied = "denied",
}

export enum ArticleStatus {
  ApprovedPrivate = "approved_private",
  ApprovedPublic = "approved_public",
  Archived = "archived",
  Draft = "draft",
  InReview = "in_review",
}

export enum AsyncStorageKey {
  Device = "device",
  Legal = "legal",
  Setting = "setting",
}

export enum Auth0Role {
  /** Full Access. Platform Administrator */
  Admin = "tractic-admin-members",
  /** Editor. Assistant to Member. Booking Services */
  Assistant = "tractic-assistant-members",
  /** No Access. Member with no access. Reference Only */
  Contact = "tractic-contact-members",
  /** Viewer. Investor of property(s) in support of a subscribed member */
  Investor = "tractic-investor-members",
  /** Viewer. Investment Bank to access financials of the property */
  InvestmentBank = "tractic-investment-bank-members",
  /** Unknown Yet */
  Referral = "tractic-referral-members",
  /** Editor. Subscribed Member. */
  Subscribed = "tractic-subscribed-members",
  /** Editor. Tax Professionals for property(s) */
  Tax = "tractic-tax-members",
  /** No Access. Interested Member but no Subscription Yet */
  Unsubscribed = "tractic-unsubscribed-members",
}

export enum BucketName {
  App = "tractic.io",
}

export enum ContactRelationship {
  All = "all",
  Bank = "bank",
  ClosingAgent = "closing",
  Financing = "financing",
  PropertyManagement = "property_management",
  Seller = "seller",
  TitleAgent = "title",
}

export enum EntityType {
  COOP = "cooperative",
  Corporation = "corporation",
  GeneralPartnership = "general_partnership",
  JoinVenture = "joint_venture",
  LLC = "limited_liability_corporation",
  LLP = "limited_liability_partnership",
  LP = "limited_partnership",
  Partnership = "partnership",
  REIT = "real_estate_investment_trust",
  SCorporation = "s_corporation",
  SoleProprietor = "sole_proprietor",
  Trust = "trust",
}

export enum FileStatus {
  Completed = "completed",
  Local = "local",
  Managed = "managed",
  Pending = "pending",
}

export enum FileTag {
  Appraisal = "Appraisal",
  Contract = "Contract",
  Insurance = "Insurance",
  Lease = "Lease",
  Maintenance = "Maintenance",
  Mortgage = "Mortgage",
  Other = "Other",
  PM = "Property Management",
  Taxes = "Taxes",
  Title = "Title/Escrow",
}

export enum FinanceType {
  BankFinanced = "bank",
  Exchange = "1031_exchange",
  HardMoneyLending = "hard_money_lending",
  SellerFinanced = "seller",
  Other = "other",
}

export enum MaintenanceStatus {
  Available = "available",
  Loading = "loading",
  Unavailable = "unavailable",
}

export enum MemberStatus {
  /** Member and Subscriber of TRACTIC */
  Subscribed = "subscribed",
  /** Supporting a subscribed member like a tax professional, investor, etc */
  Support = "support",
  /** Could be contact, interested member but not subscribed, etc */
  Unsubscribed = "unsubscribed",
}

export enum NotificationStatus {
  /** Successful Delivery */
  Sent = "sent",
  /** Unsuccessful Delivery */
  Undelivered = "undelivered",
  /** Yet to be sent. Send now */
  Unsent = "unsent",
}

export enum PropertyStatus {
  Active = "active",
  Closed = "closed",
  Escrow = "escrow",
  Sold = "sold",
}

export enum PropertyType {
  Condo = "Condo",
  Duplex = "Duplex",
  Fourplex = "Fourplex",
  SFH = "SFH",
  TownHome = "Town Home",
  Triplex = "Triplex",
  Other = "Other",
}

export enum RentalType {
  LongTermRentals = "long_term_rental",
  ShortTermRentals = "short_term_rental",
  Syndication = "syndication",
  Other = "other",
}

export enum ResourceId {
  Affiliate = "affiliate",
  Article = "article",
  Channel = "channel",
  Contact = "contact",
  Definition = "definition",
  File = "file",
  Member = "member",
  Message = "message",
  MetricsPortfolio = "metrics-portfolio",
  MetricsTemplate = "metrics-template",
  Notification = "notification",
  Preference = "preference",
  Property = "property",
  Referral = "referral",
  Report = "report",
  Setting = "setting",
  System = "system",
  Tag = "tag",
  Testimonial = "testimonial",
  Ticket = "ticket",
  TicketNote = "ticket-note",
  Transaction = "transaction",
  Unit = "unit",
  Video = "video",
}

export enum StripeSubscriptionStatus {
  Active = "active",
  All = "all",
  Cancelled = "canceled",
  Ended = "ended",
  Incomplete = "incomplete",
  Incomplete_Expired = "incomplete_expired",
  Past_Due = "past_due",
  Trialing = "trialing",
  Unpaid = "unpaid",
}

export enum SystemName {
  Appliances = "appliances",
  Fridge = "fridge",
  HVAC = "hvac",
  Roof = "roof",
  Solar = "solar",
  Stove = "stove",
  WashingMachine = "washer",
  WaterHeater = "water_heater",
  Windows = "windows",
}

export enum TestimonialCategory {
  DataManagement = "data_management",
  DocumentationStorage = "documentation_storage",
  OperationalEfficiency = "operational_efficiency",
  Overall = "overall",
  RealEstateScalability = "real_estate_scalability",
  ReportingAnalytics = "reporting_analytics",
  TaxReporting = "tax_reporting",
  Other = "other",
}

export enum TestimonialStatus {
  ApprovedPrivate = "approved_private",
  ApprovedPublic = "approved_public",
  Archived = "archived",
  Draft = "draft",
  InReview = "in_review",
}

export enum TicketStatus {
  Active = "active",
  Archived = "archived",
  Closed = "closed",
  Open = "open",
}

export enum ToggleResource {
  AffiliateModal = "affiliate-modal",
  ArticleModal = "article-modal",
  AnnualAssumptionsModal = "annual-assumptions-modal",
  ChatSidebar = "chat-sidebar",
  ContactModal = "contact-modal",
  ContactList = "contacts-list",
  DefinitionDrawer = "definition-drawer",
  DocumentModal = "document-modal",
  DocumentShareModal = "share-document-modal",
  FileModal = "file-modal",
  LeftSidebar = "left-sidebar",
  NotificationModal = "notification-modal",
  PreferenceModal = "preference-modal",
  PropertyModal = "property-modal",
  RightSidebar = "right-sidebar",
  Sidebar = "sidebar",
  SubscriptionSidebar = "subscription-sidebar",
  SystemModal = "system-modal",
  TestimonialModal = "testimonial-modal",
  TransactionExportModal = "transactions-export-modal",
  TransactionsColumns = "transaction-columns-modal",
  TransactionModal = "transaction-modal",
  TutorialModal = "tutorial-modal",
  Tour = "tour",
}

export enum TransactionType {
  Advertising = "advertising",
  Heloc = "heloc_payment",
  LegalProfessionalFee = "legal_professional_fee",
  Maintenance = "maintenance",
  Mortgage = "mortgage",
  PropertyManagementFee = "property_management_fee",
  PropertyTax = "property_tax",
  Rent = "rent",
  Repair = "repair",
  SecurityDeposit = "security_deposit",
  Utilities = "utilities",
  Other = "other",
}

export enum VideoStatus {
  ApprovedPrivate = "approved_private",
  ApprovedPublic = "approved_public",
  Archived = "archived",
  Draft = "draft",
  InReview = "in_review",
}
