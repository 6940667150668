/** Redux */
import * as types from "../actions/types";
import asyncStorage from "../../hooks/useStorage";

/** Enums */
import { AsyncStorageKey } from "types";

/** Types */
import type { IStringObject } from "types";
import type { Middleware } from "redux";

const storageMiddleware: Middleware = (store) => {
  const storage = asyncStorage();

  return (next) => (action) => {
    switch (action.type) {
      case types.GET_DEVICE_DEFAULTS_SUCCESS:
        storage
          .getItem({ key: AsyncStorageKey.Device })
          .then((response?: IStringObject) => {
            if (response) {
              store.dispatch({
                ...response,
                type: types.UPDATE_DEVICE_DETAILS,
              });
            }
          });

        next(action);
        break;

      default:
        if (
          Object.prototype.hasOwnProperty.call(action, "type") &&
          action.type
        ) {
          return next(action);
        }
    }
  };
};

export default storageMiddleware;
