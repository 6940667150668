import React, { useContext, useState } from "react";

/** Vendor */
import { TabBar } from "antd-mobile";
import { Layout, Menu, Tag, Typography } from "antd";
import { NavLink, useLocation } from "react-router-dom";

import {
  AppstoreAddOutlined,
  BuildOutlined,
  CrownOutlined,
  FileSearchOutlined,
  LineChartOutlined,
  ReadOutlined,
  TeamOutlined,
} from "@ant-design/icons";

/** Auth Context */
import { AuthContext } from "../../auth/shared/Context";

/** Custom Hooks */
import { useAppSelector } from "@hooks/useRedux";
import useDevice from "@hooks/useDevice";

/** Custom Methods */
import { formatName } from "@dist/js/support";

/** Custom Components */
import Avatar from "@components/shared/member/Avatar";

/** Custom Logos */
import Logo from "@dist/img/brand/tractic-logo-512.png";

/** Custom CSS */
import "./sidebar.css";

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState<boolean>(window.innerWidth > 768);
  const device = useDevice();
  const location = useLocation();
  const member = useAppSelector((state) => state.member.details);
  const subscription = useContext(AuthContext);

  /** Determine Subscription Text to Display in Sidebar */
  let subscription_label = "ROOKIE";
  if (subscription.stripe_subscription_level === 0) {
    subscription_label = "GUEST";
  } else if (subscription.stripe_subscription_level === 1) {
    subscription_label = "ROOKIE";
  } else if (subscription.stripe_subscription_level === 2) {
    subscription_label = "PRO";
  } else if (subscription.stripe_subscription_level === 3) {
    subscription_label = "ALL STAR";
  } else if (subscription.stripe_subscription_level === 4) {
    subscription_label = "ALL STAR";
  }

  const items = [
    {
      icon: (
        <div className="text-center">
          {React.createElement(AppstoreAddOutlined)}
        </div>
      ),
      key: "sider-menu-option-0",
      label: <NavLink to="/dashboard">Dashboard</NavLink>,
    },
    {
      icon: (
        <div className="text-center">{React.createElement(TeamOutlined)}</div>
      ),
      key: "sider-menu-option-1",
      label: <NavLink to="/contacts">Contacts</NavLink>,
    },
    {
      icon: (
        <div className="text-center">{React.createElement(BuildOutlined)}</div>
      ),
      key: "sider-menu-option-2",
      label: <NavLink to="/capex">CapEx Items</NavLink>,
    },
    {
      icon: (
        <div className="text-center">
          {React.createElement(FileSearchOutlined)}
        </div>
      ),
      key: "sider-menu-option-3",
      label: <NavLink to="/documents">Documents Hub</NavLink>,
    },
    {
      icon: (
        <div className="text-center">
          {React.createElement(LineChartOutlined)}
        </div>
      ),
      key: "sider-menu-option-4",
      label: <NavLink to="/reports">Reports</NavLink>,
    },
    {
      icon: (
        <div className="text-center">{React.createElement(ReadOutlined)}</div>
      ),
      key: "sider-menu-option-5",
      label: <NavLink to="/education">Education</NavLink>,
    },
  ];

  /** Add Definition Manager If Admin */
  if (subscription?.admin) {
    items.push({
      icon: (
        <div className="text-center">{React.createElement(CrownOutlined)}</div>
      ),
      key: "sider-menu-option-6",
      label: <NavLink to="/platform">Platform</NavLink>,
    });
  }

  items.push({
    icon: (
      <div style={{ height: 50, width: 50 }}>
        <Avatar enableTooltip={false} members={[member]} />
      </div>
    ),
    key: "sider-menu-option-7",
    label: (
      <NavLink to="/profile/details">
        <div className="user info">
          <div>{formatName(member)}</div>
          {!device.isMobile && <div>{member?.email}</div>}
        </div>
      </NavLink>
    ),
  });

  items.push({
    icon: null,
    key: "sider-menu-option-8",
    label: <Tag>{subscription_label}</Tag>,
  });

  let appliedKey = "sider-menu-option-0";
  if (location.pathname.includes("contacts")) {
    appliedKey = "sider-menu-option-1";
  } else if (location.pathname.includes("capex")) {
    appliedKey = "sider-menu-option-2";
  } else if (location.pathname.includes("documents")) {
    appliedKey = "sider-menu-option-3";
  } else if (location.pathname.includes("reports")) {
    appliedKey = "sider-menu-option-4";
  } else if (location.pathname.includes("education")) {
    appliedKey = "sider-menu-option-5";
  } else if (location.pathname.includes("platform")) {
    appliedKey = "sider-menu-option-6";
  } else if (location.pathname.includes("profile")) {
    appliedKey = "sider-menu-option-7";
  }

  const mobileIgnore = ["sider-menu-option-7"];
  return device.isMobile ? (
    <TabBar defaultActiveKey={appliedKey}>
      {items
        .filter((item) => !mobileIgnore.includes(item.key))
        .map((item) => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.label} />
        ))}
    </TabBar>
  ) : (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light"
      width={300}
    >
      <div className="logo">
        <img alt="TRACTIC" src={Logo} />
        {collapsed ? null : (
          <Typography.Title className="text-white" level={4}>
            TRACTIC
          </Typography.Title>
        )}
      </div>
      <Menu
        className={subscription?.admin ? "admin flex-1" : "not-admin flex-1"}
        items={items}
        mode="inline"
        selectedKeys={[appliedKey]}
        theme="light"
      />
    </Layout.Sider>
  );
}
