/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";

export function initializeSocketAction(current_status: string) {
  return function (dispatch: IAppDispatch) {
    dispatch({ current_status, type: types.INITIALIZE_SOCKET_CONNECTION });
  };
}

export function socketRefreshAction() {
  return function (dispatch: IAppDispatch) {
    dispatch({ type: types.SOCKET_REFRESH });
  };
}
