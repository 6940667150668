/** Vendors */
import { RouterProvider } from "react-router-dom";
import { App, ConfigProvider } from "antd";
/** Custom Hooks */
import { useAppSelector } from "./hooks/useRedux";

/** Custom Components */
import AuthBoundary from "./components/auth/views/Boundary";
/** Custom Routes for App */
import router from "./routes/index";
/** Enums */
import { Auth0Role } from "types";

//https://ant.design/docs/react/customize-theme
const antdTheme = {
  components: {
    Card: {
      colorBgContainer: "#efefef",
      padding: 20,
    },
    Layout: {
      bodyBg: "#184622",
      lightSiderBg: "#184622",
      lightTriggerBg: "#184622",
      siderBg: "#184622",
    },
    Menu: {
      bodyBg: "#1f6a30",
      itemActiveBg: "#1f6a30",
      itemColor: "#eee",
      itemHoverBg: "#fff",
      itemSelectedBg: "#fff",
    },
    Segmented: {
      itemActiveBg: "#184622",
      itemHoverColor: "#184622",
      itemSelectedBg: "#1f6a30",
      itemSelectedColor: "#ffffff",
    },
    Select: {
      multipleItemBg: "#1f6a30",
    },
  },
  token: {
    borderRadius: 6,
    borderRadiusLG: 10,
    colorError: "#F00",
    colorLink: "#184622",
    colorPrimary: "#1f6a30",
    colorSuccess: "#1f6a30",
    fontFamily: "Poppins",
    fontSizeSM: 14,
  },
};

/** Tractic Web Application */
function DesktopApp() {
  const { is_admin } = useAppSelector((state) => ({
    is_admin: state.member.details?.roles?.some(
      (role) => role?.name === Auth0Role.Admin
    ),
  }));

  return (
    <ConfigProvider theme={antdTheme}>
      <App>
        <AuthBoundary>
            <RouterProvider router={router(is_admin)} />
        </AuthBoundary>
      </App>
    </ConfigProvider>
  );
}
export default DesktopApp;
