import React, { Suspense, lazy, useEffect } from "react";

/** Vendors */
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

/** Redux */
import { searchPropertiesAction } from "@redux/actions/property";

/** Custom Hooks */
import useDelay from "@hooks/useDelay";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom Components */
import Sidebar from "../sidebar/Sidebar";
import ViewLoading from "../loading/View";
const StrapHangers = lazy(() => import("../StrapHangers"));

function AppLayout() {
  const delay = useDelay();
  const dispatch = useAppDispatch();
  const { is_active, socket } = useAppSelector((state) => ({
    is_active: state.flow.is_active,
    socket: state.socket,
  }));

  /** Step 1. Wait for websocket to connect to search for properties */
  useEffect(() => {
    if (socket.status === "connected") {
      delay(() => dispatch(searchPropertiesAction({})), 1000);
    }
  }, [socket.status]);

  // return <ViewLoading />;

  return (
    <Suspense fallback={<ViewLoading />}>
      <Layout
        className={is_active ? "flow-active" : ""}
        hasSider={true}
        style={{
          height: "100vh",
          margin: "auto",
        }}
      >
        <Sidebar />
        <Layout className="site-layout p-4">
          <Outlet />
          <StrapHangers />
        </Layout>
      </Layout>
    </Suspense>
  );
}

export default AppLayout;
