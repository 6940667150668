/** Vendors */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const details = createReducer(initialState.preference.details, (builder) => {
  builder
    .addCase(createAction(types.GET_PREFERENCE_SUCCESS), (state, action) => {
      return action.preference;
    })
    .addCase(createAction(types.TOGGLE_PREFERENCE_MODAL), (state, action) => {
      return action.details || {};
    })
    .addCase(createAction(types.UPDATE_PREFERENCE_SUCCESS), (state, action) => {
      return redux.single.updateMatchCase(state, action, "preference");
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.preference.list, (builder) => {
  builder
    .addCase(
      createAction(types.SEARCH_PREFERENCES_SUCCESS),
      (state, action) => {
        return redux.joinOrOverwrite(state, action);
      }
    )
    .addCase(createAction(types.UPDATE_PREFERENCE_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, "preference");
    })
    .addDefaultCase((state) => state);
});

const preference = combineReducers({ details, list });

export default preference;
