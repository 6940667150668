/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";
import type { IAnyObject, ISearchUnit, IUnit } from "../../types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

/* Property Unit(s) Actions */
export function createUnitAction(unit: IUnit) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "property",
      method: "PUT",
      path: "property/unit",
      type: types.CREATE_UNIT_SUCCESS,
      unit,
    });
  };
}

export function deleteUnitAction(unit: IUnit) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "property",
      method: "DELETE",
      path: "property/unit",
      type: types.DELETE_UNIT_SUCCESS,
      unit,
    });
  };
}

export function getUnitAction(unit: IUnit) {
  return (dispatch: IAppDispatch, getState: any) => {
    sendMessage(dispatch)({
      action: "property",
      method: "GET",
      path: "property/unit",
      settings: getState().setting.details,
      type: types.GET_UNIT_SUCCESS,
      unit,
    });
  };
}

export function searchUnitsAction(search: ISearchUnit) {
  return (dispatch: IAppDispatch) => {
    //Reset filtered results before populating again. edge case for no matches.
    dispatch({ results: [], type: types.SEARCH_UNITS_SUCCESS });
    sendMessage(dispatch)({
      action: "property",
      method: "GET",
      path: "property/unit/search",
      search,
      type: types.SEARCH_UNITS_SUCCESS,
    });
  };
}

export function setActiveUnitAction(unit: IUnit) {
  return (dispatch: IAppDispatch) => {
    dispatch({
      type: types.SET_ACTIVE_UNIT_SUCCESS,
      unit,
    });
  };
}

export function updateUnitAction(unit: IUnit) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "property",
      method: "POST",
      path: "property/unit",
      type: types.UPDATE_UNIT_SUCCESS,
      unit,
    });
  };
}
