/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const details = createReducer(initialState.article.details, (builder) => {
  builder
    .addCase(createAction(types.DELETE_ARTICLE_SUCCESS), () => ({}))
    .addCase(createAction(types.CREATE_ARTICLE_SUCCESS), (state, action) => {
      return action.article;
    })
    .addCase(createAction(types.GET_ARTICLE_SUCCESS), (state, action) => {
      return action.article;
    })
    .addCase(createAction(types.TOGGLE_ARTICLE_MODAL), (state, action) => {
      return action.details || {};
    })
    .addCase(createAction(types.UPDATE_ARTICLE_SUCCESS), (state, action) => {
      return { ...state, ...action.article };
    })
    .addDefaultCase((state) => state);
});

const featured = createReducer(initialState.article.featured, (builder) => {
  builder
    .addCase(
      createAction(types.GET_FEATURED_ARTICLE_SUCCESS),
      (state, action) => {
        return action.article;
      }
    )
    .addCase(createAction(types.SEARCH_ARTICLES_SUCCESS), (state, action) => {
      return action.results.sort((a, b) => b.thumbs - a.thumbs)?.[0];
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.article.list, (builder) => {
  builder
    .addCase(createAction(types.DELETE_ARTICLE_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, "article");
    })
    .addCase(createAction(types.CREATE_ARTICLE_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, "article");
    })
    .addCase(createAction(types.SEARCH_ARTICLES_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.UPDATE_ARTICLE_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, "article");
    })
    .addDefaultCase((state) => state);
});

const article = combineReducers({ details, featured, list });

export default article;
