import React, { useEffect, useState } from "react";

/** Vendors */
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

/** Redux */
import { initializeSocketAction } from "@redux/actions/socket";
import { setActiveMemberAction } from "@redux/actions/member";

/** Custom Components */
import AppLoading from "../../shared/loading/App";
import LegalBoundary from "../../shared/error/Legal";

/** Custom Context */
import AuthContext from "../shared/Context";

/** Custom Hooks */
import useMaintenance from "@hooks/useMaintenance";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Enums */
import { MaintenanceStatus } from "types";

/** Types */
import type { IMember } from "types";

function AuthBoundary({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const [shouldWait, setShouldWait] = useState<boolean>(true);
  const {
    error,
    getIdTokenClaims,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    user,
  } = useAuth0();

  const dispatch = useAppDispatch();
  const socket = useAppSelector((state) => state.socket);
  const status = useMaintenance();

  /** Step 1. Determine if library has mounted/loaded yet */
  useEffect(() => {
    if (!isLoading) {
      setShouldWait(false);
    }
  }, [isLoading]);

  /** Step 2. Verify user is logged in. Redirect otherwise */
  useEffect(() => {
    if (!isAuthenticated && !shouldWait) {
      console.log("Not Authenticated");
      loginWithRedirect();
    }
  }, [isAuthenticated, shouldWait]);

  /** Step 3. Update the state of the app */
  useEffect(() => {
    if (isAuthenticated) {
      actions.onLogin();
    }
  }, [isAuthenticated]);

  /** Step 4. On expiration of token, go to loading screen and refresh access token after 3 seconds */
  useEffect(() => {
    if (["disconnected", "refresh"].includes(socket.status)) {
      actions.onLogin();
    }
  }, [socket.status]);

  const actions = {
    onLogin: async () => {
      try {
        getIdTokenClaims().then((response) => {
          if (response) {
            axios.defaults.headers.common.Authorization = `Bearer ${response?.__raw}`;
            dispatch(initializeSocketAction(socket.status));
            dispatch(setActiveMemberAction(user as IMember));
          }
        });
      } catch (error) {
        console.log({ error });
      }
    },
  };

  /** Step 5. If user is NOT Authenticated or library is still initializing */
  if (isLoading || !isAuthenticated || socket.status === "disconnected") {
    return <AppLoading status={status} />;
  }

  /** Step 6. If platform is not available, put into maintenance mode */
  if (status !== MaintenanceStatus.Available) {
    return <AppLoading status={status} />;
  }

  /** Step 7. Auth0 Error */
  if (error) {
    /** @DEVTeam Build Error Screen */
    return <div>Oops... {error?.message}</div>;
  }

  /** Step 8. User is authenticated. Show App */
  return (
    <LegalBoundary>
      <AuthContext>{children}</AuthContext>
    </LegalBoundary>
  );
}

AuthBoundary.displayName = "Authentication Boundary";

export default AuthBoundary;
