/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const details = createReducer(initialState.setting.details, (builder) => {
  builder
    .addCase(createAction(types.CREATE_SETTING_SUCCESS), (state, action) => {
      return { ...state, ...action.setting };
    })
    .addCase(createAction(types.GET_SETTING_SUCCESS), (state, action) => {
      return { ...state, ...action.setting };
    })
    .addCase(createAction(types.UPDATE_SETTING_SUCCESS), (state, action) => {
      return { ...state, ...action.setting };
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.setting.list, (builder) => {
  builder
    .addCase(createAction(types.SEARCH_SETTINGS_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addDefaultCase((state) => state);
});

const setting = combineReducers({ details, list });

export default setting;
