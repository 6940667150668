/** Vendor */
import { combineReducers, createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";

const portfolio = createReducer(initialState.forecast.portfolio, (builder) => {
  builder
    .addCase(
      createAction(types.GET_PORTFOLIO_FORECAST_SUCCESS),
      (state, action) => {
        return action.forecast;
      }
    )
    .addDefaultCase((state) => state);
});

const property = createReducer(initialState.forecast.property, (builder) => {
  builder
    .addCase(
      createAction(types.GET_PROPERTY_FORECAST_SUCCESS),
      (state, action) => {
        return action.forecast;
      }
    )
    .addDefaultCase((state) => state);
});

const unit = createReducer(initialState.forecast.unit, (builder) => {
  builder
    .addCase(createAction(types.GET_UNIT_FORECAST_SUCCESS), (state, action) => {
      return action.forecast;
    })
    .addDefaultCase((state) => state);
});

const forecast = combineReducers({ portfolio, property, unit });

export default forecast;
