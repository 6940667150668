import { useEffect, useState } from "react";

/** Enums */
import { Auth0Role } from "types";

/** Types */
import type { IContact, IMember, IProperty } from "types";

export interface ISubState {
  /** Platform Admin */
  admin: boolean;
  /** Editor Enabled */
  enabled: boolean;
  /** Invited to a property on behalf of */
  invited: boolean;
  /** Subscribed member to this property */
  subscribed: boolean;
  /** Members Stripe Product Id */
  stripe_product_id?: string;
}

/** useSubscription is on a 'per property' basis. Update products as required */
export interface IUseSubscription {
  contacts?: IContact[];
  member: IMember;
  property?: IProperty;
}

/** View or Edit Rights Over Invited Properties */
const invitee_roles = [
  Auth0Role.Assistant,
  Auth0Role.Investor,
  Auth0Role.InvestmentBank,
  Auth0Role.Tax,
];

function useSubscription({
  contacts = [],
  member,
  property,
}: IUseSubscription) {
  const [sub, setSub] = useState<ISubState>({
    admin: false,
    enabled: false,
    invited: false,
    stripe_product_id: member?.stripe_product_id,
    stripe_subscription_level: 0,
    subscribed: false,
  });

  /** Step 1. Monitorm mebers roles. 99% of the time, its a set and forget after load */
  useEffect(() => {
    const is_admin = member?.roles?.some((role) => {
      return role.name === Auth0Role.Admin;
    });
    const is_invited = member?.roles?.some((role) => {
      return invitee_roles.includes(role.name);
    });
    const is_subscribed = member?.roles?.some((role) => {
      return role?.name === Auth0Role.Subscribed;
    });

    /** Determine Subscription Level */
    let stripe_subscription_level = 0;
    const rookie = import.meta.env.VITE_REACT_APP_STRIPE_PRODUCT_LEVEL_1_ID;
    const pro = import.meta.env.VITE_REACT_APP_STRIPE_PRODUCT_LEVEL_2_ID;
    const allstar1 = import.meta.env.VITE_REACT_APP_STRIPE_PRODUCT_LEVEL_3_ID;
    const allstar2 = import.meta.env.VITE_REACT_APP_STRIPE_PRODUCT_LEVEL_4_ID;
    if (rookie === member.stripe_product_id) stripe_subscription_level = 1;
    if (pro === member.stripe_product_id) stripe_subscription_level = 2;
    if (allstar1 === member.stripe_product_id) stripe_subscription_level = 3;
    if (allstar2 === member.stripe_product_id) stripe_subscription_level = 4;

    setSub((sub) => ({
      ...sub,
      admin: !!is_admin,
      enabled: is_admin || stripe_subscription_level > 0 || sub.enabled,
      invited: !!is_invited,
      stripe_subscription_level,
      subscribed: !!is_subscribed,
    }));
  }, [member?.roles, member?.stripe_product_id]);

  // /** Step 2. Set subscription level */
  useEffect(() => {
    setSub((sub) => ({ ...sub, stripe_product_id: member?.stripe_product_id }));
  }, [member?.stripe_product_id]);

  /** Step 3. Monitor changes in the target record and members subscription */
  useEffect(() => {
    /** Step . Platform Admin. Done. */
    if (sub.admin) return;

    /** Step . Subscribed Member. Determine if product level needs to be applied */
    if (member?.resource_name === property?.owner) {
      setSub((sub) => ({ ...sub, enabled: true }));
    } else if (sub.invited) {
      const personas = contacts.filter(
        (contact) => contact.member === member?.resource_name
      );
      const match = personas.find(
        (contact) => contact.property === property?.resource_name
      );
      const enabled = [Auth0Role.Assistant, Auth0Role.Tax].includes(
        match?.role
      );
      setSub((sub) => ({ ...sub, enabled, subscribed: false }));
    }
  }, [
    contacts?.length,
    member?.resource_name,
    property?.owner,
    property?.resource_name,
  ]);

  return sub;
}

export default useSubscription;
