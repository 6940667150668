import { useEffect, useState } from "react";

/** Vendors */
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Tooltip } from "antd";

/** Custom Hooks */
import { useAppSelector } from "@hooks/useRedux";

/** Custom Methods */
import * as methods from "@dist/js/support";

/** Enums */
import { Auth0Role } from "types";

/** Types */
import type { IMember } from "types";
import type { IRootState } from "@redux/configureStore";
import type { TooltipPlacement } from "antd/lib/tooltip/index";

interface IMemberAvatar {
  enableTooltip?: boolean;
  members: string[] | IMember[];
  placement?: TooltipPlacement;
}

const fallback =
  "https://c8.alamy.com/comp/2J3B2T7/3d-illustration-of-smiling-businessman-close-up-portrait-cute-cartoon-man-avatar-character-face-isolated-on-white-background-2J3B2T7.jpg";

function AvatarWithErrorHandler({ member }: { member: IMember }) {
  const [avatarImage, setAvatarImage] = useState({
    icon: <UserOutlined style={{ minHeight: 50, minWidth: 50 }} />,
    src: fallback,
  });

  useEffect(() => {
    setAvatarImage((state) => ({
      ...state,
      icon: null,
      src: member?.picture || "",
    }));
  }, [member?.picture]);

  const actions = {
    onError: (): boolean => {
      setAvatarImage((state) => ({
        ...state,
        icon: <UserOutlined />,
        src: fallback,
      }));
      return true;
    },
  };

  let badgeOptions = {};
  if (member?.roles?.some((role) => role.name === Auth0Role.Admin)) {
    badgeOptions = { count: "A", offset: [0, 4] };
  } else if (member?.socket_connection_active) {
    badgeOptions = { dot: true };
  }

  return (
    <Badge {...badgeOptions} color="var(--color1)">
      <Avatar
        alt={methods.formatName(member)}
        key={member.resource_name + "-avatar"}
        {...avatarImage}
        onError={actions.onError}
        shape="square"
      />
    </Badge>
  );
}

function MemberAvatar({
  enableTooltip = true,
  members = [],
  placement = "left",
}: IMemberAvatar) {
  const available = useAppSelector((state: IRootState) => ({
    members: state.member.list.filtered || [],
  }));

  const converted = members.map((member: IMember) => {
    if (typeof member !== "string") return member;
    return available.members.find((m) => m.resource_name === member);
  });

  const Avatars = converted
    .filter((member) => member)
    .map((member, i) => {
      return enableTooltip ? (
        <Tooltip
          key={member.resource_name + `-avatar-parent-${i}`}
          placement={placement}
          title={methods.formatName(member)}
        >
          <div>
            <AvatarWithErrorHandler member={member} />
          </div>
        </Tooltip>
      ) : (
        <AvatarWithErrorHandler
          key={member.resource_name + `-avatar-parent-${i}`}
          member={member}
        />
      );
    });

  return <Avatar.Group>{Avatars}</Avatar.Group>;
}

export default MemberAvatar;
