/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

const details = createReducer(initialState.definition.details, (builder) => {
  builder
    .addCase(createAction(types.CREATE_DEFINITION_SUCCESS), (state, action) => {
      return action.definition || {};
    })
    .addCase(createAction(types.DELETE_DEFINITION_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Definition);
    })
    .addCase(createAction(types.GET_DEFINITION_SUCCESS), (state, action) => {
      return action.definition || {};
    })
    .addCase(createAction(types.SET_ACTIVE_PROPERTY_SUCCESS), () => ({}))
    .addCase(createAction(types.UPDATE_DEFINITION_SUCCESS), (state, action) => {
      return redux.single.updateMatchCase(state, action, ResourceId.Definition);
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.definition.list, (builder) => {
  builder
    .addCase(createAction(types.CREATE_DEFINITION_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, ResourceId.Definition);
    })
    .addCase(createAction(types.DELETE_DEFINITION_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, ResourceId.Definition);
    })
    .addCase(createAction(types.GET_DEFINITION_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, ResourceId.Definition);
    })
    .addCase(
      createAction(types.SEARCH_DEFINITIONS_SUCCESS),
      (state, action) => {
        return redux.joinOrOverwrite(state, action);
      }
    )
    .addCase(createAction(types.UPDATE_DEFINITION_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, "definition");
    })
    .addDefaultCase((state) => state);
});

const definition = combineReducers({ details, list });

export default definition;
