import React, { useEffect, useState } from "react";

/** Vendors */
import { Button, Col, Input, Row, Select, Space, Typography } from "antd";

import {
  DownOutlined,
  LeftOutlined,
  LoadingOutlined,
  ReloadOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";

/** Custom Hooks */
import useDelay from "../../../hooks/useDelay";

/** Types */
interface ISectionAccordion {
  actions: any;
  children: React.ReactNode | Array<React.ReactNode>;
  defaultFilterValue?: string;
  defaultOpen?: boolean;
  enableCollapse?: boolean;
  enableFilter?: boolean;
  enablePagination?: boolean;
  enableProgress?: boolean;
  enableRefresh?: boolean;
  enableSearch?: boolean;
  extra?: React.ReactNode | Array<React.ReactNode>;
  filterMultiple?: boolean;
  filterOptions?: Array<{ key: string; label: string; value: any }>;
  filterPlaceholder?: string;
  innerContent?: React.ReactNode | Array<React.ReactNode>;
  open?: boolean;
  paginationSize?: number;
  progress?: number;
  searchPlaceholder?: string;
  size?: "small" | "middle" | "large";
  style?: { [key: string]: number | string };
  title: string | React.ReactNode;
  value?: string | number;
}

/**
 * @description Shared Accordion Section in Each View, Grouping Cards Together
 */
function SectionAccordion({
  actions,
  children,
  defaultFilterValue = undefined,
  defaultOpen = true,
  enableCollapse = true,
  enableFilter = false,
  enablePagination = false,
  enableProgress = false,
  enableRefresh = false,
  enableSearch = false,
  extra,
  filterMultiple = false,
  filterOptions = [],
  filterPlaceholder = "Select an option",
  innerContent = null,
  open = true,
  paginationSize = 6,
  progress = 0,
  searchPlaceholder = "Search Documents...",
  size = "middle",
  style = {},
  title = "Missing Title",
  value = undefined,
}: ISectionAccordion): React.ReactElement {
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const delay = useDelay();

  const count = Array.isArray(children) ? children.length : 0;
  const [pagination, setPagination] = useState({
    index: 0,
    max: count,
    size: paginationSize,
  });

  useEffect(() => {
    setPagination((page) => ({ ...page, max: count }));
  }, [count]);

  useEffect(() => {
    if (loading) delay(() => setLoading(false), 2500);
  }, [loading]);

  /** Step . Managed Open State from Parent Component */
  useEffect(() => {
    if (typeof open === "boolean") {
      setIsOpen(open);
    }
  }, [open]);

  /** Pagination. Pane Left */
  const localActions = {
    left: () => {
      setPagination((pagination) => ({
        ...pagination,
        index: pagination.index < 1 ? 0 : pagination.index - 1,
      }));
    },
    onClose: () => {
      const nextState = !isOpen;
      setIsOpen(nextState);
      if (typeof actions.onClose === "function") {
        actions.onClose(nextState);
      }
    },
    onFilterChange: (value: string | string[]) => {
      localActions.resetPagination();
      if (typeof actions.onFilterChange === "function") {
        actions.onFilterChange(value);
      }
    },
    onFilterClear: () => {
      localActions.resetPagination();
      if (typeof actions.onFilterClear === "function") {
        actions.onFilterClear();
      }
    },
    onRefresh: () => {
      setLoading(true);
      if (typeof actions.onRefresh === "function") {
        actions.onRefresh();
      }
    },
    resetPagination: () => {
      setPagination({
        index: 0,
        max: count,
        size: paginationSize,
      });
    },
    right: () => {
      const next = pagination.index + 1;
      setPagination((pagination) => ({
        ...pagination,
        index: next * pagination.size > count ? pagination.index : next,
      }));
    },
  };

  const render = Array.isArray(children)
    ? children?.slice(
        pagination.size * pagination.index,
        pagination.size * pagination.index + pagination.size
      )
    : children;

  return (
    <div className="tractic-card" style={style}>
      <Space className="w-100" direction="vertical" size={size}>
        <Row align="middle" gutter={[16, 16]}>
          <Col flex="1 1 auto" xs={24}>
            <Typography.Title className="m-0" level={4}>
              {title}
            </Typography.Title>
          </Col>
          <Col flex="0 1 auto" />
          <Col flex="1 1 auto" xs={24}>
            <Row align="middle" justify="end" gutter={[16, 16]}>
              {enableSearch ? (
                <Col flex="0 1 300px">
                  <Input.Search
                    allowClear
                    onChange={(event) => actions.onSearch(event.target.value)}
                    onPressEnter={actions.onSearch}
                    onSearch={actions.onSearch}
                    placeholder={searchPlaceholder}
                    size={size}
                  />
                </Col>
              ) : null}
              {enableFilter ? (
                <Col flex="0 1 200px">
                  <Select
                    allowClear
                    defaultValue={defaultFilterValue}
                    mode={filterMultiple ? "multiple" : undefined}
                    onChange={localActions.onFilterChange}
                    onClear={localActions.onFilterClear}
                    placeholder={filterPlaceholder}
                    size={size}
                    style={{ width: "100%" }}
                    value={value ? String(value) : undefined}
                  >
                    {filterOptions.map((option) => (
                      <Select.Option key={option.key} value={option.value}>
                        {option.label || option.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              ) : null}
              {extra ? <Col flex="0 1 auto">{extra}</Col> : null}
              {enableRefresh ? (
                <Col flex="0 1 50px">
                  <Button
                    onClick={localActions.onRefresh}
                    size={size}
                    type="text"
                  >
                    {loading ? <LoadingOutlined /> : <ReloadOutlined />}
                  </Button>
                </Col>
              ) : null}
              {enablePagination ? (
                <Col flex="0 1 90px">
                  <Space direction="horizontal">
                    <Button
                      className="p-0"
                      icon={<LeftOutlined />}
                      onClick={localActions.left}
                      size={size}
                      type="text"
                    />
                    <Button
                      icon={<RightOutlined />}
                      onClick={localActions.right}
                      size={size}
                      type="text"
                    />
                  </Space>
                </Col>
              ) : null}
              {enableCollapse ? (
                <Col flex="45px">
                  <Button
                    icon={isOpen ? <DownOutlined /> : <UpOutlined />}
                    onClick={localActions.onClose}
                    size={size}
                    type="text"
                  />
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
        {enableProgress && (
          <div className="progress">
            <div className="bar" style={{ width: `${progress}%` }} />
          </div>
        )}
        {innerContent ? <Row gutter={[16, 16]}>{innerContent}</Row> : null}
        {isOpen ? <Row gutter={[16, 16]}>{render}</Row> : null}
      </Space>
    </div>
  );
}

export default SectionAccordion;
