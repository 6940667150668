/** Vendors */
import _ from "lodash";
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

const details = createReducer(initialState.system.details, (builder) => {
  builder
    .addCase(createAction(types.CREATE_SYSTEM_SUCCESS), (state, action) => {
      return action.system || {};
    })
    .addCase(createAction(types.DELETE_SYSTEM_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, "system");
    })
    .addCase(createAction(types.GET_SYSTEM_SUCCESS), (state, action) => {
      return action.system || {};
    })
    .addCase(createAction(types.SET_ACTIVE_PROPERTY_SUCCESS), () => ({}))
    .addCase(createAction(types.SET_ACTIVE_SYSTEM_SUCCESS), (state, action) => {
      return action.system || {};
    })
    .addCase(createAction(types.TOGGLE_FLOW_SUCCESS), (state, action) => {
      return redux.single.applyFlowControl(state, action, ResourceId.System);
    })
    .addCase(createAction(types.UPDATE_SYSTEM_SUCCESS), (state, action) => {
      return redux.single.updateMatchCase(state, action, "system");
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.system.list, (builder) => {
  builder
    .addCase(createAction(types.CREATE_SYSTEM_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, "system");
    })
    .addCase(createAction(types.DELETE_SYSTEM_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, "system");
    })
    .addCase(createAction(types.GET_SYSTEM_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, "system");
    })
    .addCase(createAction(types.SEARCH_SYSTEMS_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.SET_ACTIVE_PROPERTY_SUCCESS), () => ({
      all: [],
      filtered: [],
    }))
    .addCase(createAction(types.UPDATE_SYSTEM_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, "system");
    })
    .addDefaultCase((state) => state);
});

const industry = createReducer(initialState.system.industry, (builder) => {
  builder
    .addCase(
      createAction(types.SEARCH_INDUSTRY_SYSTEM_DATA_SUCCESS),
      (state, action) => {
        if (action.cities) {
          return _.merge(state, { cities: action.cities });
        }
        if (action.industry) {
          return _.merge(state, action.industry);
        }
        return { average: {}, cities: [], data: [] };
      }
    )
    .addDefaultCase((state) => state);
});

const system = combineReducers({ details, industry, list });

export default system;
