/** Vendors */
import { Breadcrumb, Card, Col, Flex, Row, theme } from "antd";

function ContentView({ children, extra = null, items = [], style = {} }) {
  const { token } = theme.useToken();

  return (
    <Flex justify="center" vertical>
      <Row align="middle" className="pt-4 pb-4" gutter={[24, 24]}>
        <Col
          className="flex flex-row flex-between"
          lg={16}
          md={14}
          sm={12}
          xs={24}
        >
          <Breadcrumb className="flex flex-row" items={items} />
        </Col>
        <Col lg={8} md={10} sm={12} xs={24}>
          {extra}
        </Col>
      </Row>
      <Card
        style={style}
        styles={{ body: { borderRadius: token.borderRadius } }}
      >
        <Flex gap={24} vertical>
          {children}
        </Flex>
      </Card>
    </Flex>
  );
}

export default ContentView;
