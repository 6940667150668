/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const details = createReducer(initialState.testimonial.details, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_TESTIMONIAL_SUCCESS),
      (state, action) => ({
        ...(action.testimonial || {}),
      })
    )
    .addCase(
      createAction(types.DELETE_TESTIMONIAL_SUCCESS),
      (state, action) => {
        return redux.single.removeMatchCase(state, action, "testimonial");
      }
    )
    .addCase(createAction(types.GET_TESTIMONIAL_SUCCESS), (state, action) => ({
      ...(action.testimonial || {}),
    }))
    .addCase(createAction(types.SET_ACTIVE_PROPERTY_SUCCESS), () => ({}))
    .addCase(
      createAction(types.SET_ACTIVE_TESTIMONIAL_SUCCESS),
      (state, action) => {
        return action.testimonial || {};
      }
    )
    .addCase(createAction(types.TOGGLE_TESTIMONIAL_MODAL), (state, action) => ({
      ...(action.details || {}),
    }))
    .addCase(
      createAction(types.UPDATE_TESTIMONIAL_SUCCESS),
      (state, action) => {
        return redux.single.updateMatchCase(state, action, "testimonial");
      }
    )
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.testimonial.list, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_TESTIMONIAL_SUCCESS),
      (state, action) => {
        return redux.single.addResource(state, action, "testimonial");
      }
    )
    .addCase(
      createAction(types.DELETE_TESTIMONIAL_SUCCESS),
      (state, action) => {
        return redux.multi.removeMatchCase(state, action, "testimonial");
      }
    )
    .addCase(createAction(types.GET_TESTIMONIAL_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, "testimonial");
    })
    .addCase(
      createAction(types.SEARCH_TESTIMONIALS_SUCCESS),
      (state, action) => {
        return redux.joinOrOverwrite(state, action);
      }
    )
    .addCase(
      createAction(types.UPDATE_TESTIMONIAL_SUCCESS),
      (state, action) => {
        return redux.multi.updateMatchCase(state, action, "testimonial");
      }
    )
    .addDefaultCase((state) => state);
});

const testimonial = combineReducers({ details, list });

export default testimonial;
