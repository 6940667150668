/** Vendors */
import { Hub } from "aws-amplify/utils";

/** Redux */
import * as types from "../actions/types";

/** Types */
import type { Middleware } from "redux";

const notificationMiddleware: Middleware = () => {
  return (next) => (action) => {
    switch (action.type) {
      case types.TOGGLE_NOTIFICATION_SUCCESS:
        const data = JSON.parse(action.notification.data || "{}");
        console.log(data);
        Hub.dispatch("notification", {
          data,
          event: data?.type || "success", // 'success' | 'info' | 'warning' | 'error'
        });
        break;
      default:
        next(action);
    }
  };
};

export default notificationMiddleware;
