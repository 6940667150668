import { useEffect, useState } from "react";

/** Vendor */
import axios from "axios";

/** Enums */
import { MaintenanceStatus } from "../types";

export default function useMaintenance(): MaintenanceStatus {
  const [status, setStatus] = useState<MaintenanceStatus>(
    MaintenanceStatus.Loading
  );

  useEffect(() => {
    actions.platformStatus();
  }, []);

  const actions = {
    platformStatus: () => {
      axios
        .get("/platform/status", { params: { app: "desktop" } })
        .then(({ status }: any) => setStatus(status))
        .catch(() => setStatus(MaintenanceStatus.Unavailable));
    },
  };

  return status;
}
