import ReactDOM from "react-dom/client";

/** Vendors */
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider as ReduxProvider } from "react-redux";

/** Icons */
import "./dist/js/icons";

/** Custom CSS */
import "katex/dist/katex.min.css";
import "./dist/css/index.css";
import "./dist/css/app.css";
import "./dist/css/colors.css";
import "./dist/css/cards.css";
import "./dist/css/form.css";
import "./dist/css/format.css";
import "./dist/css/table.css";
import "./dist/css/text.css";

/** Custom Config */
import store from "./redux/configureStore";

/** Axios Setup */
import "./dist/js/setup";

/** Custom Components */
import App from "./App";

/** Components added here will _only_ be loaded in the web browser, never for server-side rendering */
const root = document.getElementById("root");

ReactDOM.createRoot(root!).render(
  <Auth0Provider
    authorizationParams={{
      redirect_uri: import.meta.env.VITE_REACT_APP_DOMAIN,
    }}
    clientId={import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID}
    domain={import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN}
  >
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
