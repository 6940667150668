import React, { Suspense, lazy } from "react";

/** Vendors */
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

/** Custom Components */
import Sidebar from "../sidebar/Sidebar";
import ViewLoading from "../loading/View";
const StrapHangers = lazy(() => import("../StrapHangers"));

function ProfileLayout({ is_active }) {
  return (
    <Suspense fallback={<ViewLoading />}>
      <Layout
        className={is_active ? "flow-active" : ""}
        hasSider={true}
        style={{
          height: "100vh",
          margin: "auto",
        }}
      >
        <Sidebar />
        <Layout className="site-layout p-4">
          <Outlet />
        </Layout>
        <StrapHangers />
      </Layout>
    </Suspense>
  );
}

export default ProfileLayout;
