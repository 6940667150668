import { useEffect, useState } from "react";

export default function useDevice() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 585);

  useEffect(() => {
    setIsMobile(window.innerWidth < 585);
  }, [window.innerWidth]);

  return {
    isMobile,
  };
}
